import React, { useState } from 'react';
import { EnterEmail } from './EnterEmail.jsx';
import { EnterVerification } from './EnterVerification.jsx';
import { EnterNewPassword } from './EnterNewPassword.jsx';
import { PasswordSuccessfullyUpdated } from './SuccessfullyUpdated';
import { ResetPasswordContext } from "../../context/ResetPasswordContext";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((_theme) => ({
  resetPassword: {
    height: '100vh'
  }
}));

export const ResetPassword = () => {
  const [step, setStep] = useState(0);
  const [counter, setCounter] = useState(90);
  const classes = useStyles();

  return (
    <ResetPasswordContext.Provider value={{ counter, setCounter }}>
      <div className={classes.resetPassword}>
        {step === 0 && <EnterEmail onSubmit={() => setStep(1)} />}
        {step === 1 && <EnterVerification onSubmit={() => setStep(2)} />}
        {step === 2 && <EnterNewPassword onSubmit={() => setStep(3)} />}
        {step === 3 && <PasswordSuccessfullyUpdated />}
      </div>
    </ResetPasswordContext.Provider>

  );
};
