import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, Button } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';
import { useStyles } from '../user-profile/components/UserProfileDialog';

export const GeneralError = (props) => {
  const { onClose, onTryAgain, isOpen } = props;

  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <div className={classes.dialog}>
        <ClearRounded
          style={{
            color: '#fff',
            backgroundColor: '#F4333D',
            borderRadius: '50%',
            borderWidth: '0px',
          }}
        />
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          Error Encountered
        </Typography>
        <Typography component="p" variant="subtitle1" className={classes.text}>
          Please try again
        </Typography>
        <div className={classes.singleButtonContainer}>
          <Button
            style={{ backgroundColor: '#000', color: '#fff', border: '1px solid #000' }}
            onClick={onTryAgain}
            data-testid="on-try-again"
          >
            Try Again
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
GeneralError.propTypes = {
  onClose: PropTypes.func,
  onTryAgain: PropTypes.func,
  isOpen: PropTypes.bool,
};
