import React, { useRef, useState } from 'react';
import { SmarterTextField } from '@components/input';
import PropTypes from 'prop-types';

export const OTP = ({ values, setValues }) => {
  const [lastValue, setLastValue] = useState('');
  const inputs = useRef([]);
  let lastIndex = values.length - 1;

  const processInput = (index) => async(e) => {
    if (/[^a-zA-Z0-9]/.test(e)) return;
    const newValues = [...values];
    newValues[index] = e;
    setValues(newValues);
    if (index !== lastIndex) {
      if (e.keyCode !== 8) {
        inputs.current[index + 1].focus();
      }
    }
    if (index === lastIndex) {
      if (!lastValue || (e && e !== lastValue)) {
        setLastValue(e);
      }
    }
  };

  const onKeyUp = (index, isCopyPaste = false) => async (e) => {
    if(isCopyPaste) {
      let pastedText = await navigator.clipboard.readText().then((clipText) => {return clipText});
      if(pastedText) {
        let pastedArr = [...Array(6)].map((char, index) => pastedText[index]);
        setValues(pastedArr);  
        inputs.current[5].focus();
      }
    }
    else {
      if (e.keyCode === 8 && index !== 0) {
        if (index < lastIndex) {
          const newValues = [...values];
          newValues[index - 1] = '';
          setValues(newValues);
          inputs.current[index - 1].focus();
        }
        if (index === lastIndex) {
          if (lastValue) {
            setLastValue('');
            inputs.current[index].focus();
          } else {
            inputs.current[index - 1].focus();
            const newValues = [...values];
            newValues[index - 1] = '';
            setValues(newValues);
          }
        }
      }
    }
  };
  return values.map((value, index) => {
    return (
      <>
        <SmarterTextField
          onChange={processInput(index)}
          onKeyUp={onKeyUp(index, false)}
          onPaste={onKeyUp(index, true)}
          value={value}
          allowClear={false}
          inputRef={(ref) => inputs.current.push(ref)}
          inputProps={{
            maxLength: 1,
            'data-testid': `enter-verification-value-${index}`,
          }}
        />
        {index < values.length - 1 && '-'}
      </>
    );
  });
};

OTP.propTypes = {
  values: PropTypes.array,
  setValues: PropTypes.func,
};
