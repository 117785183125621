import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Sidebar } from './components/Sidebar';
import { Heading } from './components/Heading';

export const PageLayout = (props) => {
  const { title, activePage, children } = props;

  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <Heading title={title} />
        <Grid container spacing={2}>
          <Grid item md={2}>
            <Sidebar activePage={activePage} />
          </Grid>
          <Grid item md={10}>
            {children}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
PageLayout.propTypes = {
  title: PropTypes.string,
  activePage: PropTypes.string,
  children: PropTypes.node,
};
