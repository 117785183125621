import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { validateEmail } from "../../../utils/utils.js";
import { InputTitle } from "./InputTitle.jsx";
import { SmarterTextField } from "../../../components/input";
import { requiredFieldErrorMessage } from "../../../features/common/config"

export const Email = (props) => {
  const {
    index,
    emails,
    patchEmails,
    setPatchEmails,
    setAsPreferred,
    isDisabled = false,
    setEmails,
    deleteEmail,
    isSubmitted,
    userDataLength,
  } = props;
  
  const isError =
    isSubmitted && !emails[index]?.email?.length
      ? true
      : emails[index].email?.length > 0 &&
        validateEmail(emails[index]?.email).length;

  const changeEmail = (value) => {
    const newEmails = emails.map((email, eIdx) => {    
      if (index === eIdx) {
        email.email = value;
        if(email?.id) {
          const newPatchEmails = patchEmails.add(email.id);
          setPatchEmails(newPatchEmails);
        }
      }
      return email;
    });
    setEmails(newEmails);
  };
  return (
    <Fragment key={`${index}-email`}>
      <Grid item xs={12}>
        <InputTitle
          index={index}
          items={emails}
          setAsPreferred={setAsPreferred}
          deleteItem={deleteEmail}
          type="email"
          userDataLength={userDataLength}
        />
        <label htmlFor={`email-${index}`}>E-mail*</label>
        <SmarterTextField
          value={emails[index].email}
          onChange={changeEmail}
          disabled={isDisabled}
          inputProps={{ "data-testid": `email-${index}` }}
          error={isError}
          helperText={
            isSubmitted && !emails[index]?.email?.length
              ? requiredFieldErrorMessage
              : emails[index]?.email?.length > 0
              ? validateEmail(emails[index].email)
              : ""
          }
          id={`email-${index}`}
        />
      </Grid>
    </Fragment>
  );
};
Email.propTypes = {
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  setAsPreferred: PropTypes.func.isRequired,
  emails: PropTypes.array.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  setEmails: PropTypes.func.isRequired,
  userDataLength: PropTypes.number,
};
