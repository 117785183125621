import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LoadingButton } from 'components/button';
import { SmarterTextField } from 'components/input';
import { Link, useParams } from 'react-router-dom';
import { LocalizedUtils, shLocale as locale } from 'utils/utils';
import { useFormik } from 'formik';
import { RegisterValidationSchema } from 'utils/RegisterValidationSchema';
import { postCreateNewAccountId, sendActivationEmail } from '../../apis';
import { getClientNameForLoginAndRegistration } from '../common/config';
import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import PhoneInput from 'react-phone-input-2';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    padding: '4rem 0',
  },
  registerBox: {
    padding: `${theme.spacing(4.8)}px ${theme.spacing(4)}px`,
    background: '#fff',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.25)',
    maxWidth: theme.spacing(40),
    margin: 'auto',
    width: '100%',
  },
  pageTitle: {
    fontSize: theme.spacing(2.6),
    fontWeight: 700,
    display: 'block',
    textAlign: 'center',
    paddingBottom: '2rem',
  },
  textLabel: {
    display: 'block',
    marginBottom: theme.spacing(0.8),
  },
  submitButton: {
    backgroundColor: '#000',
    color: '#fff',
    display: 'block',
    width: '100%',
    height: theme.spacing(6.4),
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#000',
    }
  },
  divider: {
    width: '100%',
    height: '20px',
  },
  signIn: {
    marginTop: '0.5rem',
    textAlign: 'center',
    '& a': {
      fontWeight: 700,
      textDecoration: 'none',
      color: '#000',
    },
  },
  phoneInput: {
    width: '100% !important'
  },
  phoneInputError: {
    borderColor: 'red !important'
  }
}));

export const NewRegistration = () => {
  const classes = useStyles();
  const searchParams = useParams();
  const [alertProps, setAlertProps] = useState({});
  const { isValid, dirty, values, errors, touched, isSubmitting, handleChange, handleSubmit, handleBlur, resetForm } =
    useFormik({
      initialValues: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        gender: '',
        birthday: null,
        contact_number: '',
      },
      onSubmit: async (submmitedValues) => {
        try {
          if (!searchParams?.clientName) {
            setAlertProps({
              open: true,
              message: 'Client name is missing!',
              severity: 'error',
            });
          } else {
            const { birthday, mobilePrefix, mobilePhone, email, ...payload } = submmitedValues;
            const jsonPayload = new URLSearchParams({
              ...payload,
              email,
              birthday: new Date(birthday).toISOString().split('T')[0],
              client_name: getClientNameForLoginAndRegistration(searchParams?.clientName),
            }).toString();
            const data = await postCreateNewAccountId(jsonPayload);
            if (data?.data?.userId) {
              // send activation link to the user
              // this will help user to navigate to the activation screen
              await sendActivationEmail(new URLSearchParams({ email }));
              // show the alert message
              setAlertProps({
                open: true,
                message: 'Account created successfully, please check you email for the activation!',
                severity: 'success',
              });
              resetForm(); // reset form values after submit
            } else {
              setAlertProps({
                open: true,
                message: 'An error occurred while creating the user!',
                severity: 'error',
              });
            }
          }
        } catch (error) {
          setAlertProps({
            open: true,
            message: `An error occurred${
              error?.response?.data?.detail ? `: (${error?.response?.data?.detail[0]?.msg})` : ''
            }, please try again!`,
            severity: 'error',
          });
        }
      },
      validationSchema: RegisterValidationSchema,
    });

  const handleCloseAlert = () => {
    setAlertProps({});
  };
  return (
    <div className={classes.pageContainer}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertProps?.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertProps?.severity}>
          {alertProps?.message}
        </Alert>
      </Snackbar>
      <div className={classes.registerBox}>
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          Create New Account
        </Typography>
        <form onSubmit={handleSubmit}>
          <div>
            <label className={classes.textLabel}>
              Given First Name (As per National Identity Card)
            </label>
            <SmarterTextField
              error={!!errors?.first_name && touched?.first_name}
              helperText={errors?.first_name && touched?.first_name && errors?.first_name}
              value={values.first_name}
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'first_name',
                    value,
                  },
                })
              }
              placeholder="Enter First Name"
            />
          </div>
          <div className={classes.divider} />
          <div>
            <label className={classes.textLabel}>Given Middle Name (Optional)</label>
            <SmarterTextField
              value={values.middle_name}
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'middle_name',
                    value,
                  },
                })
              }
              placeholder="Enter Middle Name"
            />
          </div>
          <div className={classes.divider} />
          <div>
            <label className={classes.textLabel}>
              Given Last Name (As per National Identity Card)
            </label>
            <SmarterTextField
              error={!!errors?.last_name && touched?.last_name}
              helperText={errors?.last_name && touched?.last_name && errors?.last_name}
              value={values.last_name}
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'last_name',
                    value,
                  },
                })
              }
              placeholder="Enter Last Name"
            />
          </div>
          <div className={classes.divider} />
          <div>
            <label className={classes.textLabel}>Email</label>
            <SmarterTextField
              error={!!errors?.email && touched?.email}
              helperText={errors?.email && touched?.email ? errors?.email : ''}
              value={values.email}
              name="email"
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'email',
                    value,
                  },
                })
              }
              onBlur={handleBlur}
              placeholder="Enter Email"
            />
          </div>
          <div className={classes.divider} />
          <div>
            <label className={classes.textLabel}>Gender</label>
            <FormControl
              error={!!errors?.gender && touched?.gender}
              variant="outlined"
              style={{ width: '100%' }}
            >
              <InputLabel shrink={false}>{!values.gender && 'Select Gender'}</InputLabel>
              <Select
                name="gender"
                value={values.gender}
                onChange={handleChange}
                inputProps={{
                  'data-testid': 'gender',
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
              <FormHelperText>{errors?.gender && touched?.gender && errors?.gender}</FormHelperText>
            </FormControl>
          </div>
          <div className={classes.divider} />
          <div>
            <label className={classes.textLabel}>Date of Birth</label>
            <div style={{ display: 'grid' }}>
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                <KeyboardDatePicker
                  openTo="year"
                  views={['year', 'month', 'date']}
                  inputVariant="outlined"
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  placeholder="Select Date of Birth"
                  value={values.birthday}
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: 'birthday',
                        value,
                      },
                    })
                  }
                  inputProps={{
                    'data-testid': 'birthday',
                  }}
                  error={!!errors?.birthday && touched?.birthday}
                  helperText={touched?.birthday && errors?.birthday}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={classes.divider} />
          <div>
            <label className={classes.textLabel}>Mobile Number</label>
            <FormControl className={classes.phoneInput}>
              <PhoneInput
                country="sg"
                value={values?.contact_number} 
                onChange={(value) => handleChange({
                  target: {
                    name: 'contact_number',
                    value,
                  },
                })}
                specialLabel={null}
                placeholder={null}
                inputProps={{
                  'data-testid': 'contact_number'
                }}
                inputClass={`${classes.phoneInput} ${!!errors?.contact_number && touched?.contact_number ? classes.phoneInputError : ''}`}
              />
              {errors?.contact_number && touched?.contact_number && <FormHelperText error>{errors?.contact_number}</FormHelperText>}
            </FormControl>
          </div>
          <LoadingButton
            disabled={!isValid || !dirty}
            loading={isSubmitting}
            classes={classes.submitButton}
            text="Sign Up"
            data-testid="submit-button"
          />
        </form>
        <Typography className={classes.signIn}>
          Already have an account? <Link to="/login">Sign In</Link> instead
        </Typography>
      </div>
    </div>
  );
};
