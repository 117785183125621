export const submitEmails = async (emails, emailLength, addEmail, patchEmails, patchEmail) => {
    // sending emails request
    const emailAPIPayloads = emails.slice(emailLength); // userData.email.length
    const emailAPIPromises = emailAPIPayloads.map((payload) =>
      addEmail(payload)
    );
    let patchEmailsCopy = [...patchEmails];

    const patchEmailAPIPromises = patchEmailsCopy.map((id) => {
      return emails.map((email, index)=> {
        if(email.id === id) {
          delete email.id
          patchEmail(id, email)
        }
      });
    })

    try { 
      await Promise.all(emailAPIPromises);
      await Promise.all(patchEmailAPIPromises);
    } catch (err) {
      console.error(err);
    }
};

export const submitContactNumbers = async (contactNumbers, contactNumberLength, addContactNumber, patchContactNumbers, patchContactNumber) => {
    // sending contact numbers request
    const contactNumberAPIPayloads = contactNumbers.slice(
        contactNumberLength
    ); //userData.contact_number.length
    const contactNumberAPIPromises = contactNumberAPIPayloads.map((payload) =>
      addContactNumber(payload)
    );
    let patchContactNumbersCopy = [...patchContactNumbers];

    const patchContactNumberAPIPromises = patchContactNumbersCopy.map((id) => {
      return contactNumbers.map((number, index)=> {
        if(number.id === id) {
          delete number.id
          patchContactNumber(id, number)
        }
      });
    })

    try {
      await Promise.all(contactNumberAPIPromises);
      await Promise.all(patchContactNumberAPIPromises);
    } catch (err) {
      console.error(err);
    }
};

export const submitAddress = async (addresses, addressLength, addAddress, patchAddresses, patchAddress) => {
    // sending addresses request
    const addressAPIPayloads = addresses.slice(addressLength); // userData.address.length
    const addressAPIPromises = addressAPIPayloads.map((payload) =>
      addAddress(payload)
    );

    let patchAddressesCopy = [...patchAddresses];
    const patchAddressAPIPromises = patchAddressesCopy.map((id) => {
      return addresses.map((address, index)=> {
        if(address.id === id) {
          delete address.id
          patchAddress(id, address)
        }
      });
    })

    try {
      await Promise.all(addressAPIPromises);
      await Promise.all(patchAddressAPIPromises);
    } catch (err) {
      console.error(err);
    }
};