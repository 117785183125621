import { makeStyles } from "@material-ui/core/styles";
export const useStylesBase = makeStyles((theme) => ({
  container: {
    background: "#fff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(8),
    "& *": {
      fontFamily: "Poppins, sans-serif !important",
      textAlign: "left",
    },
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(6)}px`,
    },

    "& h2": {
      fontSize: theme.spacing(2.8),
      lineHeight: `${theme.spacing(4.2)}px`,
    },
    "& ~ .makeStyles-container-40": {
      marginTop: theme.spacing(3),
    },
    "& label": {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2.4),
      display: "block",
    },
    "& .MuiOutlinedInput-input": {
      padding: `${theme.spacing(1.6)}px ${theme.spacing(1.4)}px`,
    },
    "& .special-label": {
      display: "none !important",
    },
    "& .MuiGrid-item": {
      position: "relative",
    },
    "& .Mui-error": {
      marginLeft: 0,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
      position: "absolute",
      top: "100%",
    },
    "& .Mui-disabled": {
      borderColor: "#BFBFBF",
      background: "#F2F2F2",
    },
    "& .MuiSvgIcon-colorPrimary": {
      color: "#03ACEF",
    },
    "& .invalid-number-message": {
      top: theme.spacing(5.6),
      left: "0px",
      padding: "0px",
    },
  },
  breadcrumb: {
    "& span": {
      color: "#088CCA",
      fontWeight: 600,
    },
  },
  accountInformationHeading: {
    fontFamily: "Playfair Display, sans-serif !important",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: theme.spacing(2.8),
    lineHeight: `${theme.spacing(4)}px`,
    [theme.breakpoints.up("sm")]: {
      margin: `${theme.spacing(2)}px 0px`,
    },
  },
  pageTitle: {
    fontWeight: "600",
    fontSize: theme.spacing(2.8),
    lineHeight: `${theme.spacing(4.2)}px`,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(-4.2),
    },
  },
  actionContainer: {
    textAlign: "center",
    margin: "auto",
    marginBottom: theme.spacing(5),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(3.6),
    maxWidth: theme.spacing(77),
    "& button": {
      maxWidth: theme.spacing(37),
      fontSize: theme.spacing(2),
      lineHeight: `${theme.spacing(2.8)}px`,
      cursor: "pointer",
      padding: theme.spacing(2),
      textTransform: "capitalize",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
    },
  },

  formTitle: {
    borderBottom: "1px solid #088CCA",
    paddingBottom: 5,
    fontSize: theme.spacing(2.2),
    lineHeight: `${theme.spacing(3.3)}px`,
    fontWeight: 600,
    marginTop: theme.spacing(3),

    "& > div": {
      color: "#088CCA",
      fontWeight: "600",
      fontSize: theme.spacing(1.6),
      lineHeight: `${theme.spacing(2.4)}px`,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      "& > img": {
        marginRight: theme.spacing(0.6),
        width: theme.spacing(1.6),
      },
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(8),
    },
  },
  formTitleDisabled: {
    "& > div": {
      color: "rgba(0,0,0,0.3)",
      cursor: "not-allowed"
    },
  },
  textField: {
    position: "relative",
    "& input": {
      paddingLeft: `${theme.spacing(3.6)}px !important`,
    },
    "& .visibility-outlined-icon": {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(4.6),
      right: theme.spacing(1.2),
    },
    "& .lock-outlined-icon": {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(4.6),
      left: theme.spacing(1.2),
      color: "#C4C4C4",
    },
  },
}));
