import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, Button } from '@material-ui/core';
import {
  DeleteOutlined,
  CheckCircleRounded,
  ClearRounded,
  WarningRounded,
} from '@material-ui/icons';

export const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontWeight: '600',
    fontSize: theme.spacing(2.8),
    lineHeight: `${theme.spacing(4.2)}px`,
    fontFamily: 'Playfair Display, sans-serif !important',
  },
  dialog: {
    padding: theme.spacing(8),
    textAlign: 'center',
    '& svg': {
      width: theme.spacing(6.4),
      height: theme.spacing(6.4),
    },
  },
  singleButtonContainer: {
    '& button': {
      fontSize: theme.spacing(1.8),
      padding: theme.spacing(1.6),
      lineHeight: `${theme.spacing(2.8)}px`,
      textTransform: 'capitalize',
      marginTop: theme.spacing(1.6),

      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(4),
        padding: `${theme.spacing(1.6)}px ${theme.spacing(8)}px`,
      },
    },
  },
  gridButtonContainer: {
    textAlign: 'center',
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3.6),
    maxWidth: theme.spacing(77),

    '& button': {
      maxWidth: theme.spacing(37),
      fontSize: theme.spacing(1.8),
      lineHeight: `${theme.spacing(2.8)}px`,
      cursor: 'pointer',
      padding: theme.spacing(1.6),
      textTransform: 'capitalize',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}));
export const UserProfileDialog = (props) => {
  const { onClose, isOpen, type, onConfirm } = props;

  const classes = useStyles();

  const item = (() => {
    let logo, title, text, secondaryButton, primaryButton;
    if (type === 'delete') {
      logo = <DeleteOutlined color="primary" />;
      title = 'Are you sure you want to delete this item?';
      secondaryButton = 'Cancel';
      primaryButton = 'Yes';
    } else if (type === 'success') {
      logo = <CheckCircleRounded style={{ color: '#41BF06' }} />;
      title = 'Success!';
      text = 'Update Successful';
      primaryButton = 'Back to My Profile';
    } else if (type === 'error') {
      logo = (
        <ClearRounded
          style={{
            color: '#fff',
            backgroundColor: '#F4333D',
            borderRadius: '50%',
            borderWidth: '0px',
          }}
        />
      );
      title = 'Error Encountered';
      text = 'Please try again';
      secondaryButton = 'Try Again';
      primaryButton = 'Back to Edit';
    } else if (type === 'errorPassword') {
      logo = (
        <ClearRounded
          style={{
            color: '#fff',
            backgroundColor: '#F4333D',
            borderRadius: '50%',
            borderWidth: '0px',
          }}
        />
      );
      title = 'Error Encountered';
      text = 'Old Password provided was incorrect. Please try again';
      secondaryButton = 'Try Again';
      primaryButton = 'OK';
    } 
    else if (type === 'passwordSame') {
      logo = (
        <ClearRounded
          style={{
            color: '#fff',
            backgroundColor: '#F4333D',
            borderRadius: '50%',
            borderWidth: '0px',
          }}
        />
      );
      title = 'Error Encountered';
      text = 'Your old and new passwords cannot be the same.';
      secondaryButton = 'Try Again';
      primaryButton = 'OK';
    } 
    else if (type === 'confirmSubmit') {
      logo = (
        <WarningRounded
          style={{
            color: '#F48120',
          }}
        />
      );
      title = 'Are you sure you want to make these changes?';
      secondaryButton = 'No';
      primaryButton = 'Yes';
    } else if (type === 'passwordChanged') {
      logo = <CheckCircleRounded style={{ color: '#41BF06' }} />;
      title = 'Success!';
      text = 'Password Successfully Updated';
      primaryButton = 'Re-Login Now';
    }
    return { logo, title, text, secondaryButton, primaryButton };
  })();

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <div className={classes.dialog}>
        {item.logo}
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          {item.title}
        </Typography>
        {type !== 'delete' && (
          <Typography component="p" variant="subtitle1" className={classes.text}>
            {item.text}
          </Typography>
        )}
        <div
          className={
            type === 'success' || type === 'passwordChanged' || type == 'errorPassword' || type == 'passwordSame'
              ? classes.singleButtonContainer
              : classes.gridButtonContainer
          }
        >
          {type !== 'success' && type !== 'passwordChanged' && type !== 'errorPassword' && type !== 'passwordSame' && (
            <Button
              style={{ backgroundColor: '#fff', color: '#000', border: '1px solid #000' }}
              onClick={onClose}
              data-testid="on-close-button"
            >
              {item.secondaryButton}
            </Button>
          )}
          <Button
            style={{ backgroundColor: '#088CCA', color: '#fff' }}
            onClick={onConfirm}
            data-testid="on-confirm-button"
          >
            {item.primaryButton}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
UserProfileDialog.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  onConfirm: PropTypes.func,
};
