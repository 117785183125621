import React, { useEffect, useState } from "react";
import { PageLayout } from "./PageLayout";
import {
  getUser,
  patchUser,
  addEmail,
  deleteEmail,
  putEmail,
  patchEmail,
  addContactNumber,
  deleteContactNumber,
  putContactNumber,
  addAddress,
  deleteAddress,
  putAddress,
  patchAddress,
  patchContactNumber,
} from "../../apis";
import { Typography } from "@material-ui/core";
import { Email } from "./components/Email";
import { ContactNumber } from "./components/ContactNumber";
import { Address } from "./components/Address";
import { ActionContainer } from "./components/ActionContainer";
import { UserProfileDialog } from "./components/UserProfileDialog";
import { FormTitle } from "./components/FormTitle";
import { MyDetails } from "./MyDetails.jsx";
import "react-phone-input-2/lib/material.css";
import {
  reversePreferredElement,
  validateEmail,
} from "../../utils/utils.js";
import { submitEmails, submitContactNumbers, submitAddress} from "./utils/utils.js";
import { useStylesBase } from "./useStylesBase.js";

export const MyAccount = () => {
  const [userData, setUserData] = useState({});
  const [isError, setIsError] = useState(false);
  const [emails, setEmails] = useState([]);
  const [patchEmails, setPatchEmails] = useState(new Set());
  const [addresses, setAddresses] = useState([]);
  const [patchAddresses, setPatchAddresses] = useState(new Set());
  const [contactNumbers, setContactNumbers] = useState([]);
  const [patchContactNumbers, setPatchContactNumbers] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [activeDeleteItem, setActiveDeleteItem] = useState({});
  const [activeModalType, setActiveModalType] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPreSubmittedEmail, setIsPreSubmittedEmail] = useState(false);
  const [isPreSubmittedContactNumber, setIsPreSubmittedContactNumber] = useState(false);
  const [isPreSubmittedAddress, setIsPreSubmittedAddress] = useState(false);

  async function fetchData() {
    let user = await getUser();
    setActiveModalType(user.data ? "success" : "error");
    setUserData({ ...user.data });
    const reversedPreferredEmails = reversePreferredElement(user.data.email);
    setEmails(reversedPreferredEmails);
    const reversedPreferredAddresses = reversePreferredElement(
      user.data.address
    );
    setAddresses([...reversedPreferredAddresses]);
    const reversedPreferredContactNumbers = reversePreferredElement(
      user.data.contact_number
    );
    setContactNumbers([...reversedPreferredContactNumbers]);
    setIsLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setIsError(false);
    if (userData) {
      if (!userData.firstName || !userData.lastName) {
        setIsError(true);
      }
    }
  }, [userData]);
  const baseClasses = useStylesBase();

  const clickDelete = (type, item, index) => {
    if (type === "email" && index >= userData.email.length) {
      const emailsMirror = [...emails];
      emailsMirror.splice(index, 1);
      setEmails(emailsMirror);
      setIsPreSubmittedEmail(false);
    } else if (
      type === "contactNumber" &&
      index >= userData.contact_number.length
    ) {
      const contactNumbersMirror = [...contactNumbers];
      contactNumbersMirror.splice(index, 1);
      setContactNumbers(contactNumbersMirror);
      setIsPreSubmittedContactNumber(false);
    } else if (type === "address" && index >= userData.address.length) {
      const addressesMirror = [...addresses];
      addressesMirror.splice(index, 1);
      setAddresses(addressesMirror);
      setIsPreSubmittedAddress(false);
    } else {
      setOpenPopup(true);
      setActiveModalType("delete");
      setActiveDeleteItem({
        type,
        item,
        index,
      });
    }
  };
  const clickSetAsPreferred = async (type, id) => {
    if (type === "email") {
      await putEmail(id);
    } else if (type === "contactNumber") {
      await putContactNumber(id);
    } else if (type === "address") {
      await putAddress(id);
    }
    fetchData();
  };
  const clickConfirm = async (item) => {
    const type = item.type;
    const id = item.item.id;

    if (type === "email") {
      await deleteEmail(id);
    } else if (type === "contactNumber") {
      await deleteContactNumber(id);
    } else if (type === "address") {
      await deleteAddress(id);
    }

    fetchData();
    setOpenPopup(false);
  };


  const submit = async () => {
    if (!isError) {
      setIsSubmitted(true);
      setIsPreSubmittedAddress(false);
      setIsPreSubmittedContactNumber(false);
      setIsPreSubmittedEmail(false);

      await patchUser({
        firstName: userData.firstName,
        lastName: userData.lastName,
        gender: userData.gender,
        birthday: userData.birthday,
      });
      await submitEmails(emails, userData.email.length, addEmail, patchEmails, patchEmail);
      await submitContactNumbers(contactNumbers, userData.contact_number.length, addContactNumber, patchContactNumbers, patchContactNumber);
      await submitAddress(addresses, userData.address.length, addAddress, patchAddresses, patchAddress);
      await fetchData();
    }
  };

  const confirmSubmit = () => {
    let thereIsError = false;
    addresses.slice(userData.address.length).forEach((address) => {
      if (
        !address?.street.length ||
        !address?.block.length ||
        !address?.zipcode?.length
      ) {
        thereIsError = true;
        setIsPreSubmittedAddress(true);
      }
    });
    contactNumbers
      .slice(userData.contact_number.length)
      .forEach((contactNumber) => {
        if (contactNumber.contactNumber.length <= 5) {
          thereIsError = true;
          setIsPreSubmittedContactNumber(true);
        }
      });
    emails.slice(userData.email.length).forEach((email) => {
      if (!email?.email.length || validateEmail(email.email).length) {
        thereIsError = true;
        setIsPreSubmittedEmail(true);
      }
    });
    if (!thereIsError) {
      setActiveModalType("confirmSubmit");
      setOpenPopup(true);
    }
  };
  const userDialogClose = () => {
    setOpenPopup(false);
    if (activeModalType === "error") {
      fetchData();
    }
  };
  const userDialogConfirm = () => {
    if (activeModalType === "delete") {
      clickConfirm(activeDeleteItem);
    } else if (activeModalType === "success") {
      setOpenPopup(false);
      setIsSubmitted(false);
      window.location.reload();
    } else if (activeModalType === "error") {
      setOpenPopup(false);
      setIsSubmitted(false);
    } else if (activeModalType === "confirmSubmit") {
      setOpenPopup(false);
      setIsSubmitted(true);
      submit();
    }
  };

  return (
    <PageLayout title="My Account" activePage="my-account">
      {isLoading ? (
        <div className={baseClasses.container}>Loading...</div>
      ) : (
        <>
          <div className={baseClasses.container}>
            <MyDetails userData={userData} setUserData={setUserData} />
          </div>
          <div className={baseClasses.container}>
            <Typography
              component="h2"
              variant="subtitle1"
              className={baseClasses.pageTitle}
            >
              Contact Details
            </Typography>
            <FormTitle type="email" emails={emails} setEmails={setEmails} />
            {emails.map((email, index) => {
              return (
                <Email
                  index={index}
                  emails={emails}
                  userDataLength={userData.email.length}
                  setEmails={setEmails}
                //  isDisabled={email.id ? true : false}
                  patchEmails={patchEmails}
                  setPatchEmails={setPatchEmails}
                  deleteEmail={() => clickDelete("email", email, index)}
                  setAsPreferred={() => clickSetAsPreferred("email", email.id)}
                  isSubmitted={isPreSubmittedEmail}
                />
              );
            })}

            <FormTitle
              type="contactNumber"
              contactNumbers={contactNumbers}
              setContactNumbers={setContactNumbers}
            />

            {contactNumbers.map((contactNumber, index) => {
              return (
                <ContactNumber
                  index={index}
                  contactNumbers={contactNumbers}
                  userDataLength={userData.contact_number.length}
                  setContactNumbers={setContactNumbers}
                 // isDisabled={contactNumber.id ? true : false}
                 patchContactNumbers={patchContactNumbers}
                 setPatchContactNumbers={setPatchContactNumbers}
                  deleteContactNumber={() =>
                    clickDelete("contactNumber", contactNumber, index)
                  }
                  setAsPreferred={() =>
                    clickSetAsPreferred("contactNumber", contactNumber.id)
                  }
                  isSubmitted={isPreSubmittedContactNumber}
                />
              );
            })}
            <FormTitle
              type="addressDetail"
              addresses={addresses}
              setAddresses={setAddresses}
            />

            {addresses.map((address, index) => {
              return (
                <Address
                  index={index}
                  addresses={addresses}
                  userDataLength={userData.address.length}
                  address={address}
                  setAddresses={setAddresses}
                 // isDisabled={address.id ? true : false}
                  patchAddresses={patchAddresses}
                  setPatchAddresses={setPatchAddresses}
                  deleteAddress={() => clickDelete("address", address, index)}
                  setAsPreferred={() =>
                    clickSetAsPreferred("address", address.id)
                  }
                  isSubmitted={isPreSubmittedAddress}
                />
              );
            })}
          </div>
        </>
      )}
      <ActionContainer
        isError={isError}
        userData={userData}
        patchUser={patchUser}
        emails={emails}
        addEmail={addEmail}
        contactNumbers={contactNumbers}
        addContactNumber={addContactNumber}
        addresses={addresses}
        addAddress={addAddress}
        fetchData={fetchData}
        submit={confirmSubmit}
      />
      <UserProfileDialog
        onClose={userDialogClose}
        isOpen={openPopup || isSubmitted}
        type={activeModalType}
        onConfirm={userDialogConfirm}
      />
    </PageLayout>
  );
};
