const USER_AUTH_STORAGE_KEY = 'user';

export const checkIsUserLoggedIn = () => {
  const userAuth = getUserAuth();
  return !!userAuth;
};

export const getUserAuth = () => {
  if (localStorage) {
    const loggedUser = localStorage.getItem(USER_AUTH_STORAGE_KEY);
    if (!(loggedUser && typeof loggedUser === 'string')) {
      return null;
    }
    const parsedLoggedUser = JSON.parse(loggedUser);
    if (!hasAccessToken(parsedLoggedUser) && !hasRoles(parsedLoggedUser)) {
      return null;
    }
    return parsedLoggedUser;
  }
  return null;
};

function hasAccessToken(user) {
  return 'access_token' in user && typeof user.access_token === 'string';
}

function hasRoles(user) {
  return 'roles' in user && Array.isArray(user.roles);
}
