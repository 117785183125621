import { clientLogoConfig, allianz, smarterhealth, admedika, fwd, fwdMo, sjmc, lippoLife, hsbcLife, sequisLife } from '../../common/config';
import { getRoleUserDoctor } from '../../../apis';
import { Compare } from '@smarterhealth/utilities';

export const invalidMessageEmailPasswordCombination = (msg) => {
    if(msg?.includes("no_of_attempt_left")) {
        return "Invalid email/password combination. Please try again."
    }
    return "Locked" 
}

    
export const errorMessageEmail = (isErrorRoleNames, ACCOUNT_NOT_SET_WORDING, error, noOfAttemptLeft) => {
    if (isErrorRoleNames) {
      return ACCOUNT_NOT_SET_WORDING;
    } else if (error.includes("does not exist")) {
      return error;
    } else if (error.includes("Invalid")) {
      return (
        <p>
          {error} <br/> Attempts left: {noOfAttemptLeft}
        </p>
      );
    }
  };
  
export const errorMessagePassword = (isErrorRoleNames, ACCOUNT_NOT_SET_WORDING, error, resetPassword, resetPasswordLink, noOfAttemptLeft) => {
    if (isErrorRoleNames) {
      return ACCOUNT_NOT_SET_WORDING;
    } else if (error.includes("Invalid")) {
      return (
        <p>
          {error} <br/> Attempts left: {noOfAttemptLeft}
        </p>
      );
    } else if (error.includes("does not exist")) {
      return error;
    } else if (error) {
      return (
        <div>
          Your account has been locked due to consecutive failed login attempts.<br/>
          Please <span onClick={resetPassword} className={resetPasswordLink}>reset your password</span> your password to unlock your account.
        </div>
      );
    }
  };
  

export const postLoginData = (user, meta) => {
  localStorage.setItem('metadata', JSON.stringify(meta));
  localStorage.setItem('user', JSON.stringify(user)); // for smart contract
  localStorage.setItem('logo', JSON.stringify(clientLogoConfig(meta.clientId)));

  document.cookie = `client=${meta.clientId}; path=/`;
  document.cookie = `clientId=${meta.client}; path=/`;
  document.cookie = `clientCountry=${meta.countryId}; path=/`;
  document.cookie =  `userId=${meta.userId}; path=/`;
  document.cookie = `roleId=${meta.roleNames[0]}; path=/`;

  meta.roleNames?.map(async(roleName) => {
    if(roleName === 'ROLE_ADMIN' || roleName === 'Specialist' || roleName === 'ROLE_SUPER_ADMIN' || roleName === 'Provider Contract Manager') {
      try {
        let doctor = await getRoleUserDoctor();
        user = {
          ...user,
          id: doctor?.data?.content?.doctorId || null
        }
        localStorage.setItem('user', JSON.stringify(user)); // for smart contract
      } catch {
        console.log('Unable to get user doctor')
      }

    }
  });
}

export const replaceParentWithChild = (obj) => {

  let modifiedObject = {
    ...obj,
    label: obj?.menus[0].label,
    url: obj?.menus[0]?.url,
    menus: obj?.menus[0]?.subMenu || obj?.menus[0]?.menus
  }
  return modifiedObject;
}


export const restructureMenu = (menu) => {
  //replace single root option with sub-menu options
  if(menu[0]?.menus?.length >= 1) {
    menu = menu[0].menus
  }
  for (let i = 0; i < menu.length; i++) {
    if(menu[i]?.menus?.length === 1) {
      menu[i] = replaceParentWithChild(menu[i])
    }
  }
  return menu
}

export const redirectIfNoClientName = (clientName) => {
  const CLIENTS = [
    allianz,
    smarterhealth,
    admedika,
    fwd,
    fwdMo,
    sjmc,
    lippoLife,
    hsbcLife,
    sequisLife,
  ];
  const loweredCaseClient = clientName.toLowerCase();
  if(!Compare.indexOfIgnoreCase(CLIENTS, loweredCaseClient)) {
    window.location.href = '/uapp/login';
  }
}