import { CLIENT_NAMES } from '../../utils/constant';

//get time zone e.g. [Asia/Singapore]
export const getTimeZone = (date) => date.toString().match(/\[(.*?)\]/)[0]; //NOSONAR - to find alternative solution instead of using regex

//get time zone string e.g. Asia/Singapore
export const getTimeZoneString = (date) => date.toString().match(/\[(.*?)\]/)[1]; //NOSONAR - to find alternative solution instead of using regex

export const tzValue = (tz) => (tz ? tz : 'Asia/Singapore');

//timezone
export const dateConvert = (date, tz) =>
  new Date(
    new Date(date.toString().replace(getTimeZone(date), '')).toLocaleString('en-US', {
      timeZone: tzValue(tz),
    }),
  );

//e.g format: 0830 to 1230
export const convertAppointmentTime = (start, end) =>
  `${new Date(start).getHours() < 10 ? '0' : ''}${new Date(start).getHours()}${
    new Date(start).getMinutes() > 0 ? '' : '0'
  }${new Date(start).getMinutes()} to ${new Date(end).getHours() < 10 ? '0' : ''}${new Date(
    end,
  ).getHours()}${new Date(end).getMinutes() > 0 ? '' : '0'}${new Date(end).getMinutes()}`;

//e.g format: 1 MAR 2021
export const convertAppointmentDate = (date) =>
  `${new Date(date)
    .toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: '2-digit' })
    .toUpperCase()}`;

export const convertFullDisplayDate = (date) =>
  date
    ? `${new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`
    : '';

export const getClientCountryForPDPA = (client) =>
  client.toLowerCase() === allianz.toLowerCase() ? 'my' : 'sg';

export const getClientNameForLoginAndRegistration = (client) => {
  if (client?.toLowerCase() === allianz.toLowerCase()) {
    return allianz;
  }
  return client?.toLowerCase() === admedika.toLowerCase() ? admedika : smarterhealth;
};
export const clientLogoConfig = (clientName) => {
  const mappedClientName = CLIENT_NAMES.indexOf(clientName) !== -1 ? clientName : smarterhealth;
  return `${window.location.origin}/arrow/api/files/clientName/${mappedClientName}/site/logo.png`;
};

export const maxPatientChoice = 2;

export const minPatientChoice = 2;

export const maxPatientChoiceString = 'TWO';

export const authValue = process.env.REACT_APP_ARROW_AUTH ?? 'abc123';

export const paginationLimit = 5;

export const filterDateRangeInMonths = 3;

export const bentoApiPrefix = 'api/v1';

export const arrowApiPrefix = 'arrow/api';

export const apptAttended = 5;

export const apptNoShow = 4;

export const apptCancel = 2;

export const fullCalendarMinTime = '07:00';

export const fullCalendarMaxTime = '22:00';

export const allianz = 'allianz';

export const smarterhealth = 'SmarterHealth';

export const admedika = 'admedika';

export const fwd = 'fwd';

export const fwdMo = 'fwdmo';

export const sjmc = 'sjmc';

export const lippoLife = 'lippolife';

export const hsbcLife = 'hsbclife';

export const sequisLife = 'sequisLife';

export const PREVIOUS_URL_KEY = 'previousUrl';

export const SELECTED_PAYOR_KEY = 'selectedPayor';

export const USER_PAYORS = 'userPayors';

export const clientKey = 'client';

export const worklistRowsPerPage = [25, 50, 100];

export const defaultRowsPerPage = 25;

export const maxPackage = 5;

//cookie keys
export const roleTypeKey = 'roleId';

export const userIdKey = 'userId';

export const clientCountryKey = 'clientCountry';

export const CLIENT_ID = 'clientId';

//client countries
export const singapore = 'singapore';

export const malaysia = 'malaysia';

export const indonesia = 'indonesia';

export const hongKong = 'hong kong';

//roleType values
export const liaisonManager = 'Liaison Manager';

export const specialist = 'Specialist';

export const patient = 'Patient';

export const PAYOR_PAF_MANAGER = 'Payor Paf Manager';

//e.g. 01 Nov 2021 for MUI DatePicker
export const muiDateFormat = 'dd MMM yyyy';

//e.g. 01 Nov 2021 for moment.format
export const momentDateFormat = 'DD MMM YYYY';

// Google Analytics Tracking
export const GTAG_SRC = 'https://www.googletagmanager.com/gtag/js?id=G-C9DGXBFK3J';
export const GTAG_DATA_LAYER =
  "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-C9DGXBFK3J');";
export const FWD = 'FWD';

// Error Message
export const requiredFieldErrorMessage = 'This field cannot be empty';
