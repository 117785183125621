import React, { useState, useEffect, useMemo } from 'react';
import { postLogout, getPasswordStrength } from './apis';
import { ThemeProvider } from '@material-ui/styles';
import { UserContext } from './context/UserContext';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { MyAccount } from './features/user-profile/MyAccount.jsx';
import { AccountSecurity } from './features/user-profile/AccountSecurity.jsx';
import { NewRegistration } from 'features/registration/NewRegistration';
import { PersonalProfile } from './features/registration/PersonalProfile.jsx';
import { ReviewSubmit } from './features/registration/ReviewSubmit.jsx';
import { Login } from './features/login/Login.jsx';
import { AfterLogin } from './features/login/AfterLogin.jsx';
import { OktaSamlAuthentication } from './features/login/OktaSamlAuthentication.jsx';
import { ResetPassword } from './features/forgot-password/ResetPassword';
import { Activation } from './features/activation/index.jsx';
import { NotFound, NoPermissionPage } from 'features/error-pages';
import './App.css';
import { userTheme } from './features/app_theme/theme';
import { FetchMenu } from '@smarterhealth/utilities';
import { httpResponseEventEmitter, HttpResponseEvents } from './services/http_services/utils';
import { ProtectedLayout } from 'layouts/ProtectedLayout';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { GTAG_SRC, GTAG_DATA_LAYER, USER_PAYORS } from './features/common/config';
import { PayorSelection } from 'features/login/PayorSelection';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthOSignIn } from './features/authO/AuthOSignIn.jsx';
import { Auth0AuthenticationPage } from './features/authO/Auth0AuthenticationPage.jsx';
import { convertPasswordStrengthMessage } from './utils/utils';
import { Auth0Provider } from '@auth0/auth0-react';

const CLIENT_ID = '0oaa60b3hi92Ie3it5d7';
const ISSUER = 'https://dev-02469146.okta.com/oauth2/default';
const REDIRECT_URI = `${process.env.PUBLIC_URL}/login/callback`;
const SCOPES = 'openid profile email';

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
};

const oktaAuth = new OktaAuth(config);

export const ReactHelmet = () => {
  return (
    <Helmet>
      <title>Smarter Health</title>
      <script async src={GTAG_SRC}></script>
      <script>{GTAG_DATA_LAYER}</script>
    </Helmet>
  );
};

function App() {
  let metadata = JSON.parse(localStorage?.getItem('metadata')) || null;
  const [clientId, setClientId] = useState(metadata?.clientId || null);
  const [roleNames, setRoleNames] = useState(metadata?.roleNames || []);
  const [userId, setUserId] = useState(metadata?.userId || null);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [passwordRules, setPasswordRules] = useState({});
  const [passwordConfiguration, setPasswordConfiguration] = useState([]);
  const { args } = FetchMenu.useMenu();
  const navigate = useNavigate();
  const [newArgs, setNewArgs] = useState({
    ...args,
    ...(args.headerProps.onLogoClick = () => navigate('/choose-menu')),
  });

  useEffect(async () => {
    let user = JSON.parse(localStorage?.getItem('user')) || null;
    if (
      !window.location.href.includes('login') &&
      !window.location.href.includes('activation') &&
      !window.location.href.includes('signup') &&
      !window.location.href.includes('forgot-password') &&
      !window.location.href.includes('no-permission?isOkta') &&
      !window.location.href.toLowerCase().includes('fwd/okt') &&
      !window.location.href.includes('ext/callback')
    ) {
      if (!roleNames.length || !metadata || !user) {
        await postLogout({ user_id: userId });
        localStorage.removeItem('metadata');
        localStorage.removeItem('user');
        localStorage.removeItem('previousUrl');
        localStorage.removeItem(USER_PAYORS);

        const clientId = window.sessionStorage.getItem('client');

        if (clientId) {
          if (metadata && !roleNames.length) {
            window.location.href = `/uapp/${clientId}/login?roleNames=false`;
          } else {
            window.location.href = `/uapp/${clientId}/login`;
          }
        } else if (!roleNames.length) {
          window.location.href = `/uapp/${clientId}/login?roleNames=false`;
        } else {
          window.location.href = '/uapp/login';
        }
      }
    }
    setNewArgs({
      ...args,
    });
  }, [roleNames, clientId, args]);

  useEffect(() => {
    function handleErrorResponse() {
      navigate('/no-permission');
    }
    httpResponseEventEmitter.on(HttpResponseEvents.permissionDenied, handleErrorResponse);
  }, []);

  useEffect(async () => {
    let res = await getPasswordStrength('general');
    const convert =  convertPasswordStrengthMessage(res?.data);
    setPasswordRules(res?.data);
    setPasswordConfiguration(convert);
  }, []);

  const context = useMemo(
    () => ({
      clientId,
      setClientId,
      userId,
      roleNames,
      setRoleNames,
      newArgs,
      setNewArgs,
      activationSuccess,
      setActivationSuccess,
      passwordConfiguration,
      passwordRules
    }),
    [clientId, userId, roleNames, newArgs, activationSuccess, passwordConfiguration, passwordRules],
  );

  let isUAT = window.location.href.includes('uat') && window.location.hostname.endsWith('.fwd.com');


  return (
    <Auth0Provider
      domain={isUAT ? "fwdssodevhk.au.auth0.com" : ""}
      clientId={isUAT ? "6sr2QMhtCtdoYz5XikSaObNCKZX1iXfs" : ""}
      redirectUri={`${window.location.origin}/uapp/FWD/ext/callback`}
    >
      <HelmetProvider context={{}}>
        <ReactHelmet />
        <ThemeProvider theme={userTheme}>
          <UserContext.Provider value={context}>
            <div className="user-profile-page" data-testid="userApp_user-profile-page">
              <Grid>
                <Routes>
                  <Route
                    exact
                    path="/user-profile/my-account"
                    element={
                      <ProtectedLayout {...newArgs}>
                        <MyAccount />
                      </ProtectedLayout>
                    }
                  />
                  <Route
                    exact
                    path="/user-profile/account-security"
                    element={
                      <ProtectedLayout {...newArgs}>
                        <AccountSecurity />
                      </ProtectedLayout>
                    }
                  />
                  <Route exact path="/forgot-password" element={<ResetPassword />} />
                  <Route exact path="/:clientName/login" element={<Login />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/FWD/okt/int" element={<OktaSamlAuthentication />} />
                  <Route exact path="/FWD/okt/ext" element={<OktaSamlAuthentication />} />
                  <Route exact path="/FWD/ext/callback" element={<Auth0AuthenticationPage />} />
                  <Route
                    path="/choose-menu"
                    element={
                      <ProtectedLayout {...newArgs}>
                        <AfterLogin />
                      </ProtectedLayout>
                    }
                  />
                  <Route
                    path="/choose-payor"
                    element={
                      <ProtectedLayout {...newArgs} isHideSwitchPayor={true}>
                        <PayorSelection />
                      </ProtectedLayout>
                    }
                  />
                  <Route
                    exact
                    path="/signup/:clientName/accountsettings"
                    element={<NewRegistration />}
                  />
                  <Route
                    exact
                    path="/signup/:clientName/personalprofile"
                    element={<PersonalProfile />}
                  />
                  <Route
                    exact
                    path="/signup/:clientName/review/submit"
                    element={<ReviewSubmit />}
                  />
                  <Route exact path="/activation" element={<Activation />} />
                  <Route path="/page-not-found" element={<NotFound />} />
                  <Route path="/no-permission" element={<NoPermissionPage {...newArgs} />} />
                  <Route exact path="/auth-login" element={<AuthOSignIn />} />
                  <Route path="*" element={<Navigate to="/page-not-found" />} />
                </Routes>
              </Grid>
            </div>
          </UserContext.Provider>
        </ThemeProvider>
      </HelmetProvider>
    </Auth0Provider>
  );
}

export default App;
