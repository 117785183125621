import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

export const LoadingButton = ({
  classes,
  loading,
  text,
  loadingColor = 'inherit',
  loadingSize = 24,
  ...props
}) => {
  const buttonStyles = useStyles({ loadingSize });
  return (
    <Button
      className={clsx(classes, buttonStyles.root, buttonStyles.primaryButton)}
      type="submit"
      {...props}
    >
      {loading ? <CircularProgress color={loadingColor} size={loadingSize} /> : text}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: (props) => `${props.loadingSize * 3.75}px`,
    minHeight: (props) => `${props.loadingSize * 1.4}px`,
  },
  primaryButton: {
    color: 'white',
    backgroundColor: '#03ACEF',
    '&:hover': {
      backgroundColor: '#03ACEF',
    },
    "&:disabled": {
      backgroundColor: '#d5d5d5'
    }
  },
}));
