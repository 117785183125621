import PropTypes from "prop-types";
import { LockOutlined, Warning } from "@material-ui/icons";
import { SmarterTextField } from "@components/input";
import { ToggleVisibilityIcon } from "@components/toggle-visibility-icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  passwordField: {
    position: "relative",
    "& input": {
      padding: `${theme.spacing(1.6)}px ${theme.spacing(1.4)}px`,
      paddingLeft: `${theme.spacing(4.5)}px !important`,
    },
    "& .visibility-outlined-icon, .error-icon": {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(4.6),
      right: theme.spacing(1.2),
    },
    "& .lock-outlined-icon": {
      position: "absolute",
      cursor: "pointer",
      top: theme.spacing(4.6),
      left: theme.spacing(1.2),
      color: "#C4C4C4",
    },
  },
  passwordLabel: {
    fontSize: theme.spacing(1.6),
    lineHeight: `${theme.spacing(2.4)}px`,
    color: "#000",
    marginBottom: theme.spacing(1),
    display: "block",
  },
}));

export const Password = ({
  label,
  id,
  type,
  value,
  onChange,
  error,
  helperText,
  placeholder,
  onToggle,
  allowClear = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.passwordField}>
      <label className={classes.passwordLabel} htmlFor={id}>
        {label}
      </label>
      <LockOutlined className="lock-outlined-icon" />
      <SmarterTextField
        id={id}
        inputProps={{ "data-testid": id }}
        type={type}
        value={value}
        onChange={onChange}
        allowClear={allowClear}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
      />
      {error ? (
        <Warning
          style={{
            color: "#F03D3E",
          }}
          className="error-icon"
          onClick={onToggle}
        />
      ) : (
        <ToggleVisibilityIcon visible={type === "text"} onToggle={onToggle} />
      )}
    </div>
  );
};
Password.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  onToggle: PropTypes.func,
};