import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid,
  Typography
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { RegistrationButtons, ReviewForm, CompleteMsg } from './RegistrationComponents';
import { Wrapper } from './Wrapper.jsx';
import { getClientNameForLoginAndRegistration } from '../common/config';
import { postSubmitUser } from "../../apis";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(2.6),
    fontWeight: 600
  },
  container:{
    flexGrow: 1,
  },
  divContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
  textColor: {
    color: '#666666',
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  buttons: {
    width: '180px',
    height: '50px',
    color: 'white',
    marginTop: 25,
  },
  mobileView: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      justifyItems: 'center'
    }
  },
  userInput: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
}));

export const ReviewSubmit = (props) => {
  const [completed, setCompleted] = useState(false);
  const [reviewInfo, setReviewInfo] = useState([]);
  const [fieldValue, setFieldValue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [isErrorSubmit, setIsErrorSubmit] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [registrationJSON, setRegistrationJSON] = useState({});
  
  const searchParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();


  const initFormFields = () => {
    if(location.state){
      setReviewInfo(location.state.formFields);
      setUserId(location.state.userId);
      setDateFormat(location.state.dateFormat);
      setFieldValue(location.state.formValues);
      setRegistrationJSON(location.state.registrationJson)
    }
  }


  const submitPopup = async() => {
    setIsErrorSubmit(false);
    if(!userId || !fieldValue || !dateFormat){
      console.log('Could not retrieve state information from previous page')
      setIsErrorSubmit(true)
      return
    }
    setIsLoading(true);
    if(registrationJSON) {
      try {
        await postSubmitUser(userId, registrationJSON);
        window.location.hash = 'completed';
        setCompleted(true);
        setIsLoading(false);
      } catch (err) {
        setIsErrorSubmit(true);
        setIsLoading(false);
      }
    } 
  }

  useEffect(() => {
    const client = searchParams?.clientName ? searchParams.clientName: null
    initFormFields();
    setClientName(getClientNameForLoginAndRegistration(client))
  }, []);

  return (
    <Wrapper>
  
      <Grid container direction="row" justifyContent="space-evenly" className={classes.container}>
        <Grid item xs={12} className={classes.mobileView}>
          <div className={classes.divContainer}>
            {
              isErrorSubmit && <Alert severity="error">Failed to submit.</Alert>
            }
            <div style={{display: completed ? 'none' : null}}>
              <Grid container direction="column" spacing={3} >
                <Grid item style={{marginRight: 'auto'}}>
                  <Typography id="title" className={classes.title}>Review & Submit</Typography>
                </Grid>
                <Grid item style={{width: '100%', marginBottom: 20}}>
                  <span style={{fontSize: 16}}>Are the following details correct?</span>

                  <ReviewForm
                    fieldArray={reviewInfo ? reviewInfo : []}
                    className={classes.userInput}
                    fieldValue={fieldValue}
                  />

                  <RegistrationButtons
                    onClickPrevious={()=> navigate(`/signup/${clientName}/personalprofile`, {state: {userId: userId, formValues: fieldValue}})}
                    onClick={submitPopup}
                    text="Submit"
                    loading={isLoading}
                    data-testid="user-app_reviewSubmit_submit"
                  />
                </Grid>
              </Grid>
            </div>
            
            <div style={{display: completed ? null : 'none'}}>
              <CompleteMsg
                className={classes.text}
                onClick={()=> navigate(`/${clientName}/login`, {state: {isBR: false}})}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Wrapper>

  );

}
