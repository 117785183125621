import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  validation: {
    display: "flex",
    alignItems: "center",
  },
  default: {
    color: "#BFBFBF",
  },
  passed: {
    color: "#62BE37",
  },
}));

const Validation = ({passwordConfiguration, errors}) => {

  const classes = useStyles();

  return (
    <>
      {passwordConfiguration.map((config) => {
        return (
          <div className={`${classes.validation} ${errors[config[0]] ? classes.passed : classes.default}`} key={config[0]}>
            <CheckIcon /> {config[1]}
          </div>
        )
      })
      }
    </>
  );
};

export default Validation;
