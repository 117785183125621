import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PersonOutline, LockOutlined } from "@material-ui/icons";

export const Sidebar = (props) => {
  const { activePage } = props;
  const useStyles = makeStyles((theme) => ({
    container: {
      "& ul": {
        padding: "0px",
      },
    },
    accountLink: {
      textDecoration: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(0.8)}px ${theme.spacing(1.2)}px`,
      borderRadius: theme.spacing(0.4),
      width: theme.spacing(18.8),
      color: "#000",
      "&.active": {
        color: "#088CCA",
        background: "#EEFAFF",
        borderRadius: theme.spacing(0.4),
        fontWeight: "600",
        "& svg": {
          color: "#03ACEF",
        },
      },
      "& svg": {
        marginRight: theme.spacing(0.8),
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(2.8),
      },
    },
  }));
  const classes = useStyles();
  let navigate = useNavigate();
  const redirectMyAccount = () => {
    navigate('/user-profile/my-account');
  };
  const redirectSecurity = () => {
    navigate('/user-profile/account-security');
  };
  
  return (
    <div className={classes.container}>
      <ul>
        <Link
          className={`${classes.accountLink} ${
            activePage === "my-account" ? "active" : ""
          }`}
          onClick={redirectMyAccount}
          id="my-account-link"
          data-testid="my-account-link"
        >
          <PersonOutline
            color={activePage === "my-account" ? "primary" : "disabled"}
            alt="ic_my_account"
          />
          My Account
        </Link>
        <Link
          className={`${classes.accountLink} ${
            activePage === "account-security" ? "active" : ""
          }`}
          id="account-security-link"
          data-testid="account-security-link"
          onClick={redirectSecurity}
        >
          <LockOutlined
            color={activePage === "account-security" ? "primary" : "disabled"}
            alt="ic_account_security"
          />
          Security
        </Link>
      </ul>
    </div>
  );
};
Sidebar.propTypes = {
  activePage: PropTypes.string.isRequired,
};
