import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { UserContext } from "../../context/UserContext";
import { Typography, Button } from "@material-ui/core";
import { LoginModal } from "./components/LoginModal";
import { SmarterTextField, Password } from "@components/input";
import { makeStyles } from "@material-ui/core/styles";
import { postLogin, getMetaData } from '../../apis';
import { Alert } from "@material-ui/lab";
import { allianz } from '../common/config';
import { invalidMessageEmailPasswordCombination, errorMessageEmail, errorMessagePassword, postLoginData, redirectIfNoClientName } from "./utils/utils";
import AllianzLandingMobile from '@images/clients/allianz/landing-page-mobile.svg';
import AllianzLandingDesktop from '@images/clients/allianz/landing-page-desktop.svg';

export const useStyles = makeStyles((theme) => ({
  allianzContainer: {
    background: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh'
  },
  allianzInnerContainer: {
    maxWidth: `${theme.spacing(90)}px`,
    margin: 'auto'
  },
  allianzImage: {
    maxWidth: '100%'
  },
  oktaLogo: {
    width: theme.spacing(20),
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(5)
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12px 70px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 50px',
    },
  },
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#fff',
  },
  loginBox: {
    padding: `${theme.spacing(4.8)}px ${theme.spacing(4)}px`,
    background: "#fff",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    maxWidth: theme.spacing(52),
    margin: "auto",
    width: "100%"
  },
  pageTitle: {
    fontSize: theme.spacing(2.6),
    fontWeight: 700,
    display: "block",
    textAlign: "center",
  },
  pageSubtitle: {
    fontWeight: 400,
    display: "block",
    textAlign: "center",
  },
  textField: {
    marginBottom: theme.spacing(2.4),

    "& input": {
      padding: `${theme.spacing(1.6)}px ${theme.spacing(1.4)}px`,
    },
  },
  textLabel: {
    display: "block",
    marginBottom: theme.spacing(0.8),
  },
  buttonLogin: {
    backgroundColor: "#000",
    color: "#fff",
    display: "block",
    width: "100%",
    height: theme.spacing(6.4),
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(5.2),
  },
  signUpInfo: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },
  forgotPassword: {
    display: "block",
    textAlign: "right",
    fontSize: theme.spacing(1.6),
    lineHeight: `${theme.spacing(1.8)}px`,
    marginTop: theme.spacing(0.8),
    cursor: "pointer",
  },
  resetPasswordLink: {
    cursor: "pointer",
    color: theme.palette.primary.main
  },
  signUpLink: { textDecoration: "underline", fontWeight: 600, color: "#000" },
  hyperLink: { textDecoration: 'none', color: "#000" }
}));
export const Login = () => {
  let navigate = useNavigate();
  let { clientName } = useParams();
  if (!clientName) {
    clientName = 'SmarterHealth'
  }
  else {
    redirectIfNoClientName(clientName);
  }

  // useEffect(() => {
  //   if (window.location.href.includes('fwd')) {
  //     navigate("/auth-login");
  //   }
  // }, []);


  const [isClientAllianz, setIsClientAllianz] = useState(clientName.toLowerCase() === allianz);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState({
    value: "",
    type: "password",
  });
  const [error, setError] = useState("");
  const [noOfAttemptLeft, setNoOfAttemptLeft] = useState(5);
  const [isErrorRoleNames, setIsErrorRoleNames] = useState(window.location.href.includes("roleNames=false"));
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);
  const { setClientId, setRoleNames, activationSuccess } = useContext(UserContext)


  const onToggleVisibilityHandler = () => {
    setPassword({
      ...password,
      type: password.type === "text" ? "password" : "text",
    });
  };
  function onChangeEmail(val) {
    setEmail(val)
    setError("")
  }
  function onChangePassword(val) {
    setPassword({
      ...password,
      value: val,
    });
    setError("")
  };
  function onForgotPassword() {
    window.location.assign("/uapp/forgot-password");
  };

  function onSignup() {
    window.location.assign(`/uapp/signup/${clientName}/accountsettings`);
  };

  const onSubmit = async (e) => {
    localStorage.removeItem("metadata");
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password.value);
    if (clientName) {
      window.sessionStorage.setItem('client', clientName);
    }

    try {
      let resUser = await postLogin(formData);
      if (resUser.data.access_token) {
        localStorage.setItem('user', JSON.stringify(resUser.data));
        try {
          let res = await getMetaData();
          if (res) {
            let meta = res.data;
            let user = resUser.data;
            user = {
              ...user,
              roles: meta?.roleNames,
              id: null
            }
            postLoginData(user, meta);
            setClientId(meta.clientId)
            setRoleNames(meta.roleNames);
            navigate("/choose-payor");
          }
        }
        catch (error) {
          console.log(error);
        }
      }
    }
    catch ({ response }) {
      let detail = response?.data?.detail;
      let errorMsg = invalidMessageEmailPasswordCombination(detail);
      let noAttemptLeft = detail?.slice(detail.length - 2, detail.length - 1);
      if (response?.status === 400) {
        if (detail.includes("does not exist")) {
          setError(response?.data?.detail)
        }
        else if (detail.includes("expired")) {
          setIsPasswordExpired(true)
        }
        else if (detail.includes("no_of_attempt_left")) {
          setError(errorMsg);
          setNoOfAttemptLeft(noAttemptLeft);
        }
      }
      else {
        setError(errorMsg);
      }
    }
  }

  const resetPassword = () => {
    navigate("/forgot-password");
  }
  const classes = useStyles();
  const ACCOUNT_NOT_SET_WORDING = "Your account was not properly set. Contact admin."

  if (isClientAllianz) {
    return (
      <div className={classes.allianzContainer}>
        <div className={classes.allianzInnerContainer}>
          <img src={isMobile ? AllianzLandingMobile : AllianzLandingDesktop} alt="allianz-container" className={classes.allianzImage} id="user-app_allianz-login-image" />
          <Button className={classes.button} variant="contained" onClick={() => setIsClientAllianz(false)} data-testid="allianz_main_button">
            GOT IT!
          </Button>
        </div>
      </div>
    )
  }


  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginBox}>
        {activationSuccess && <Alert severity="success">
          Account successfully activated. You may now login
        </Alert>}
        <Typography
          component="h2"
          variant="subtitle1"
          className={classes.pageTitle}
        >
          Welcome!
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.pageSubtitle}
        >
          Please login to your account
        </Typography>
        <form onSubmit={onSubmit}>
          <div className={classes.textField}>
            <label className={classes.textLabel} htmlFor="user-app_login_email">
              Email
            </label>
            <SmarterTextField
              placeholder="Enter Email"
              value={email}
              onChange={onChangeEmail}
              allowClear={false}
              error={error || isErrorRoleNames}
              helperText={errorMessageEmail(isErrorRoleNames, ACCOUNT_NOT_SET_WORDING, error, noOfAttemptLeft)}
              id="user-app_login_email"
              inputProps={{ 'data-testid': 'user-app_login_email' }}
            />
          </div>
          <Password
            label="Password"
            type={password.type}
            value={password.value}
            onChange={onChangePassword}
            onToggle={onToggleVisibilityHandler}
            placeholder="Enter Password"
            error={error || isErrorRoleNames}
            helperText={errorMessagePassword(isErrorRoleNames, ACCOUNT_NOT_SET_WORDING, error, resetPassword, classes.resetPasswordLink, noOfAttemptLeft)}
            id="user_app-login-password"
            inputProps={{ 'data-testid': 'user_app-login-password' }}
          />
          <Typography
            component="p"
            className={classes.forgotPassword}
            onClick={onForgotPassword}
            id="user_app-forgot-password"
            data-testid="user_app-forgot-password"
          >
            Forgot password?
          </Typography>
          <Button
            variant="contained"
            type="submit"
            className={classes.buttonLogin}
            disabled={email === '' || password.value === ''}
            data-testid="user_app-login-button"
          >
            Login
          </Button>

          <Typography
            component="p"
            variant="subtitle1"
            className={classes.signUpInfo}
          >
            Smarter Health <strong><a href="https://platform.smarterhealth.sg/user/tnc " className={classes.hyperLink}>Terms of Use</a></strong> & <strong><a href="https://www.smarterhealth.sg/privacy-policy/" className={classes.hyperLink}>Privacy Policy</a></strong>
          </Typography>

          <Typography
            component="p"
            variant="subtitle1"
            className={classes.signUpInfo}
          >
            Don’t have an account?{" "}
            <a onClick={onSignup} data-testid="user_app-sign-up" className={classes.signUpLink}>Sign up</a> instead
          </Typography>
        </form>
      </div>

      <LoginModal
        isOpen={isPasswordExpired}
        onClose={() => setIsPasswordExpired(false)}
        clickResetPassword={() => resetPassword()}
      />

    </div>
  );
};
