export class EventEmitter {
  #events = {};

  off(eventName, listener) {
    if (!this.#events[eventName]) return;

    this.#events[eventName].delete(listener);
  }

  removeAllListeners(eventName) {
    delete this.#events[eventName];
  }

  on(eventName, listener) {
    if (!(eventName in this.#events)) {
      const listeners = new Map();
      listeners.set(listener, listener);
      this.#events[eventName] = listeners;
    } else {
      this.#events[eventName].set(listener, listener);
    }
  }

  emit(eventName, ...payload) {
    const listeners = this.#events[eventName];
    if (!listeners) return;

    listeners.forEach((listener) => {
      listener(...payload);
    });
  }
}

// ----------------------------------------------------
// UserAuthStorage
// ----------------------------------------------------

const USER_AUTH_STORAGE_KEY = 'user';

class UserAuthStorage {
  setUserAuth(user) {
    localStorage.setItem(USER_AUTH_STORAGE_KEY, user);
  }

  getUserAuth() {
    const loggedUser = localStorage.getItem(USER_AUTH_STORAGE_KEY);
    if (!(loggedUser && typeof loggedUser === 'string')) {
      return null;
    }
    const parsedLoggedUser = JSON.parse(loggedUser);
    if (!hasAccessToken(parsedLoggedUser) && !hasRoles(parsedLoggedUser)) {
      return null;
    }
    return parsedLoggedUser;
  }

  removeUserAuth() {
    localStorage.removeItem(USER_AUTH_STORAGE_KEY);
  }
}

export const userAuthStorage = new UserAuthStorage();

export function hasAccessToken(user) {
  return 'access_token' in user && typeof user.access_token === 'string';
}

export function hasRoles(user) {
  return 'roles' in user && Array.isArray(user.roles);
}

// ----------------------------------------------------
// HTTP response event emitter
// ----------------------------------------------------
export const httpResponseEventEmitter = new EventEmitter();
export const HttpResponseEvents = {
  permissionDenied: 'permissionDenied',
  sessionTimeout: 'sessionTimeout',
};
