import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "context/UserContext";
import { ResetPasswordContext } from "../../context/ResetPasswordContext";
import { isPasswordValid } from '../../utils/utils';
import { Check as CheckIcon } from '@material-ui/icons';
import { postResetPassword } from '../../apis';
import { ResetPasswordPage } from './ResetPasswordPage';
import { Password } from './Password';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  requiredLabels: {
    '& > div': {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '4px',
      },
    }
  },
  timeLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  startAgain: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontWeight: 600
  },
  disabledPointer: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    color: '#ABABAB',
    fontWeight: 400
  }
}));

export const EnterNewPassword = ({ onSubmit }) => {
  const [password, setPassword] = useState({
    pwd: '',
    confirmPwd: '',
  });

  const [errors, setErrors] = useState({});
  const [isGeneralError, setIsGeneralError] = useState(false);
  const classes = useStyles();
  const { counter, setCounter } = useContext(ResetPasswordContext);
  const { passwordConfiguration, passwordRules } = useContext(UserContext);

  useEffect(() => {
    let intervalId = setInterval(() => {
      setCounter((prevState) => prevState - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [counter]);

  const onChange = (key) => {
    return (value) => {
      if (key === 'pwd') {
        setErrors({ ...isPasswordValid(value, passwordRules) });
      }
      setPassword((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    };
  };

  const closeErrorModal = () => {
    setIsGeneralError(false);
  };

  const clickContinue = async () => {
    try {
      const formData = new FormData();
      formData.append("new_password", password.confirmPwd);
      await postResetPassword(formData);
      onSubmit();
    } catch (error) {
      setIsGeneralError(true);
      return 400;
    }
  };
  const reloadPage = () => {
    if(counter <= 0 ) {
      window.location.reload(false)
    }
  }
  let isPasswordError = password.confirmPwd.length > 0 && (password.pwd !== password.confirmPwd);

  return (
    <ResetPasswordPage
      title="Enter New Password"
      description="Please create a new password for your account"
      buttonProps={{
        text: 'Continue',
        onClick: clickContinue,
        disabled:
          !password.pwd || !password.confirmPwd || password.pwd !== password.confirmPwd || 
          Object.values(errors).some((error) => error === false) || counter <= 0,
      }}
      error={isGeneralError}
      handleClose={closeErrorModal}
    >
      <Password
        label="New Password"
        id="resetPassword-new-password"
        onChangePassword={onChange('pwd')}
        error={isPasswordError}
      />

      <Password
        label="Re-enter Password"
        id="resetPassword-reenter-new-password"
        onChangePassword={onChange('confirmPwd')}
        error={isPasswordError}
        helperText={
          isPasswordError
            ? 'Passwords don’t match'
            : ''
        }
      />
      <div className={classes.timeLeft}>
        Time Left: {`${counter > 0 ? counter : 0}s`} <div className={`${classes.startAgain} ${counter > 0 ? classes.disabledPointer : ''}`} onClick={reloadPage}>Start Again</div>
      </div>

      <div className={classes.requiredLabels}>
        {passwordConfiguration.map((config, index) => {
          return (
            <div
              style={{ color: errors[config[0]] ? '#62BE37' : '#ABABAB' }}
              key={`requiredLabel-${index}`}
            >
              <CheckIcon />
              {config[1]}
            </div>
          );
        })}
      </div>
    </ResetPasswordPage>
  );
};
