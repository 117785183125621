import { useState, useEffect, useContext } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { PREVIOUS_URL_KEY } from 'features/common/config';
import { Helmet } from 'react-helmet-async';
import { getUserPayors } from 'apis/apis';
import { FetchMenu } from '@smarterhealth/utilities';
import { UserContext } from 'context/UserContext';
import { PayorConfirmationContext } from '../../context/PayorConfirmationContext';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '900px',
    margin: 'auto',
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
    lineHeight: `${theme.spacing(3.8)}px`,
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: theme.spacing(1.8),
    lineHeight: `${theme.spacing(2.7)}px`,
    background: '#FFFFFF',
    color: '#03ACEF',
    border: '1px solid #03ACEF',
    padding: theme.spacing(3),
    margin: theme.spacing(1),
    boxShadow: '3px 4px 7px rgba(166, 166, 166, 0.2)',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    alignSelf: 'stretch',
    height: 'calc(100% - 80px)',
  },
  defaultItem: {
    background: '#FFFFFF',
    color: '#03ACEF',
    border: '1px solid #03ACEF',
  },
  selectedItem: {
    background: '#F6FCFF',
    color: '#C4C4C4',
    border: '1px solid #C4C4C4',
  },
  buttonConfirm: {
    backgroundColor: '#000',
    color: '#fff',
    display: 'block',
    width: '360px',
    height: '70px',
    fontSize: '20px',
    marginTop: '40px',
    '&:hover': {
      background: '#000',
    },
  },
}));

const HEADER_TEXT = 'Select Payor';

export const PayorSelection = () => {
  const [selection, setSelection] = useState(null);
  const [payors, setPayors] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const { args } = FetchMenu.useMenu();
  const { setNewArgs } = useContext(UserContext);
  const { handleChangePayor } = useContext(PayorConfirmationContext);

  useEffect(() => {
    setNewArgs({ ...args });
  }, [args]);

  let previousUrl = localStorage.getItem(PREVIOUS_URL_KEY) || null;

  const getPayors = async () => {
    const res = await getUserPayors();
    res.data && setPayors(res.data);
  };

  const handleRedirect = () => {
    if (previousUrl && !previousUrl.includes('login')) {
      window.location.href = previousUrl;
    } else {
      navigate('/choose-menu');
    }
  };

  useEffect(() => {
    getPayors();
  }, []);

  useEffect(() => {
    if (payors?.length === 1) {
      handleRedirect();
    }
  }, [payors]);

  const handleConfirm = () => {
    handleChangePayor(selection);
  };

  const handleAlignment = () => {
    return payors.length === 2 ? 'center' : 'unset';
  };

  return (
    <>
      <Helmet>
        <title>Choose Payor</title>
      </Helmet>
      <div className={classes.container} style={{ display: payors?.length < 2 ? 'none' : null }}>
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          {HEADER_TEXT}
        </Typography>
        <Grid container spacing={1} style={{ justifyContent: handleAlignment() }}>
          {payors.map((payor) => {
            return (
              <Grid item xs={12} sm={4} key={payor.payorId}>
                <div
                  className={`${classes.gridItem} ${
                    selection === payor.payorId ? classes.selectedItem : classes.defaultItem
                  }`}
                  onClick={() => setSelection(payor.payorId)}
                >
                  {payor.displayName}
                </div>
              </Grid>
            );
          })}
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            className={classes.buttonConfirm}
            disableElevation
            disableRipple
            onClick={handleConfirm}
            disabled={!selection}
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};
