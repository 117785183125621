import Cookies from 'universal-cookie';
import { get, post, put, del, patch } from '../services/http_services/http_services';
import { arrowApiPrefix, bentoApiPrefix } from '../features/common/config';

const cookies = new Cookies();
const userId = cookies.get('userId');
const client = cookies.get('client');
const roleId = cookies.get('roleId');

export const discoveryApiPrefix = 'discovery/api/v1';

export const pdsApiPrefix = 'pds/v1';
export const userApiPrefix = 'users/v1/user';
export const userApiV2Prefix = 'users/v2/user';
export const usersApiV2Prefix = 'users/v2/users';
export const authApiV2Prefix = 'auth/v2/auth';
export const SMART_PANEL_ADMIN_PREFIX = 'smart-panel/admin/api';

export const getMyAccountMenu = () => get(`${arrowApiPrefix}/user/menu`);
export const getSidebar = () => get(`${arrowApiPrefix}/sidebar/${client}`);
export const getCountries = () => get(`${discoveryApiPrefix}/country`);

export const getConsolidatedCountries = () =>
  get(`${discoveryApiPrefix}/country/consolidated/client/${client}`);

export const getRegionState = (country) => get(`${discoveryApiPrefix}/country/${country}/region`);

export const getCities = (country, region) =>
  get(`${discoveryApiPrefix}/country/${country}/region/${region}/city`);

export const getDoctor = (specialistId) =>
  post(`${arrowApiPrefix}/specialist/${specialistId}/details?clientName=${client}`, {});

export const getDoctors = (payload) =>
  post(`${discoveryApiPrefix}/doctor`, { ...payload, clientName: client });

export const getDoctorProfilePic = (url) => get(url);

export const postBookingInitiation = (payload) =>
  post(`${arrowApiPrefix}/appointment/opd/create/${userId}/client/${client}`, payload);

export const getSpecialties = (country) =>
  get(`${discoveryApiPrefix}/specialty/country/${country}`);

export const getHealthParams = () =>
  get(`${arrowApiPrefix}/mcu/attributes/${userId}/client/${client}`);

export const postQueryForRecommendations = (payload) =>
  post(`${arrowApiPrefix}/mcu/recommendations/${userId}/client/${client}`, payload);

export const getUser = () => get(`${userApiV2Prefix}/profile`);
export const patchUser = (payload) => patch(`${userApiV2Prefix}/profile`, payload);
export const addEmail = (payload) => post(`${userApiV2Prefix}/email`, payload);
export const deleteEmail = (id) => del(`${userApiV2Prefix}/email/${id}`);
export const putEmail = (id) => put(`${userApiV2Prefix}/email/${id}/preferred`);
export const patchEmail = (id, payload) => patch(`${userApiV2Prefix}/email/${id}`, payload);
export const addContactNumber = (payload) => post(`${userApiV2Prefix}/contact_number`, payload);
export const deleteContactNumber = (id) => del(`${userApiV2Prefix}/contact_number/${id}`);
export const putContactNumber = (id) => put(`${userApiV2Prefix}/contact_number/${id}/preferred`);
export const patchContactNumber = (id, payload) =>
  patch(`${userApiV2Prefix}/contact_number/${id}`, payload);
export const addAddress = (payload) => post(`${userApiV2Prefix}/address`, payload);
export const deleteAddress = (id) => del(`${userApiV2Prefix}/address/${id}`);
export const putAddress = (id) => put(`${userApiV2Prefix}/address/${id}/preferred`);
export const patchAddress = (id, payload) => patch(`${userApiV2Prefix}/address/${id}`, payload);
export const getUserAttributes = () => get(`user/profile/${userId}/attributes`);

export const postForPackageLocations = ({ packageId, country, region, city }) =>
  post(`${arrowApiPrefix}/mcu/package/${packageId}/locations/client/${client}`, {
    country,
    region,
    city,
  });

export const putSelectedLocation = (payload) =>
  put(`/arrow/api/mcu/selectlocation/${userId}/client/${client}`, payload);

//case details related
export const getCaseDetails = (serviceType, requesterId) =>
  get(`${arrowApiPrefix}/v1/${serviceType}/refNo/${requesterId}/details/${userId}`);

export const getBookingDetails = (unitId, requesterId) =>
  get(`${bentoApiPrefix}/booking/unitId/${unitId}/requesterId/${requesterId}/details`);

//calendar related
export const getMCUCalendar = () => get(`${bentoApiPrefix}/mcu/provider/booking`);

export const postProviderClinicSchedule = (payload) =>
  post(`${bentoApiPrefix}/slot/unitIds`, payload);

export const updateAppointmentStatus = (payload) => put(`${bentoApiPrefix}/booking`, payload);

export const getOPDCalendarDoctorFirst = (providerId) =>
  get(`${bentoApiPrefix}/provider/${providerId}/booking/resource`);

export const getOPDCalendarLocationFirst = (providerId) =>
  get(`${bentoApiPrefix}/provider/${providerId}/booking/location`);

export const getProviderSchedule = (unitId) => get(`${bentoApiPrefix}/slot/unitId/${unitId}`);

export const postCreateSchedule = (unitId, payload) =>
  post(`${bentoApiPrefix}/slot/time/unitId/${unitId}`, payload);

export const postCreateScheduleSubmit = (unitId, requesterId, payload) =>
  post(`${bentoApiPrefix}/slot/book/unitId/${unitId}/requesterId/${requesterId}`, payload);

export const getPAFCaseDetails = (caseId) =>
  get(`${pdsApiPrefix}/epaf/summary/caseId/${caseId}/roleType/${roleId}`);

export const postHospitalCharges = (payload) => post(`${pdsApiPrefix}/hospital/cost`, payload);

//login
export const postLogin = (payload) => post(`${authApiV2Prefix}/login`, payload);

export const postOktaLogin = (payload) => post(`${authApiV2Prefix}/okta/login`, payload);
export const postAuth0Login = (payload) => post(`${authApiV2Prefix}/auth0/login`, payload);

//logout
export const postLogout = (payload) => post(`${authApiV2Prefix}/logout`, payload);

export const getMetaData = () => get(`${userApiV2Prefix}/metadata`);

export const getVerifyToken = () => get(`${authApiV2Prefix}/token/jwt/verify`);

export const getRoleUserDoctor = () => get(`${SMART_PANEL_ADMIN_PREFIX}/v1/users/userInfo`);

export const getMenu = (clientId, roleName) =>
  get(`/tree/v1/payors/${clientId}/roles/attributes/menu?roleName=${roleName}`, {
    Authorization: 'abc123',
  });

// reset password
export const postForgotPassword = (payload) =>
  post(`${userApiV2Prefix}/forgot-password`, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });
export const postVerifyOTP = (payload) => post(`${authApiV2Prefix}/token/otp/verify`, payload);

export const postResetPassword = (payload) =>
  post(`${userApiV2Prefix}/reset-password`, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

// change password
export const postChangePassword = (payload) =>
  post(`${userApiV2Prefix}/change-password`, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  export const getPasswordStrength = (accountType) =>
  get(`${userApiV2Prefix}/password_configuration/${accountType}`);

// user sign up
export const postCreateNewAccountId = (payload) =>
  post(`${userApiV2Prefix}/register`, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

export const sendActivationEmail = (payload) =>
  post(`${userApiV2Prefix}/activate-password`, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });

export const postSubmitUser = (id, payload) => post(`${usersApiV2Prefix}/profile/${id}`, payload);

// user activation
export const postCreateNewPassword = ({ email, new_password }) =>
  post(
    `${usersApiV2Prefix}/activate-password/${encodeURIComponent(email)}`,
    new URLSearchParams({ new_password }),
    { 'Content-Type': 'application/x-www-form-urlencoded' },
  );

// FWD SAML
export const postFWDSAML = (payload) =>
  post(`${authApiV2Prefix}/token/saml-resp`, payload, {
    'Content-Type': 'application/x-www-form-urlencoded',
  });
export const putEnableStatus = (user) => put(`${usersApiV2Prefix}/status/${user}`);

export const getUserPayors = () => get(`${userApiV2Prefix}/payors`);

export const postPayorToken = (payload) => post(`${authApiV2Prefix}/token/jwt/payor`, payload);
