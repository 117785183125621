import _ from 'lodash';
import { INFRA_MAPPING } from './constant';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import locale from 'date-fns/locale/en-US';

export const formatByte = (bytes, unit, decimal = 2) => {
  const corresponding = {
    KB: 1024,
    MB: 1024 * 1024,
  };
  return (bytes / corresponding[unit]).toFixed(decimal);
};

export const getValueOrDefault = (value, defaultValue = '') => {
  if (value) return value;
  else return defaultValue;
};

export const getValueByCondition = (condition, ifValue, elseValue) => {
  if (condition) return ifValue;
  else return elseValue;
};

export const numberSeparator = (bigNum) =>
  String(bigNum).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

export const separateObject = (obj) => [Object.keys(obj)[0], Object.values(obj)[0]];

export const pennify = (num) => _.round(num, 2).toFixed(2);

export const reversePreferredElement = (arr) => {
  const result = [];
  if (arr) {
    for (const element of arr) {
      if (!element.preferred) {
        result.push(element);
      } else {
        result.unshift(element);
      }
    }
  }
  return result;
};

export const validateEmail = (email = '') => {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return 'E-mail address must be valid';
  }
  return '';
};

export function detectEnv() {
  const host = window?.location?.host;
  for (const [key, value] of Object.entries(INFRA_MAPPING)) {
    if (value === host) {
      return key;
    }
  }
  return 'local';
}

export const redirectUser = (metadata, isLoggedIn) => {
  if (isLoggedIn) {
    window.location.href = '/uapp/choose-menu';
  } else {
    if (metadata?.client && metadata?.client.toLowerCase() !== 'smarterhealth') {
      localStorage.clear();
      window.location.href = `/uapp/${metadata?.client}/login`;
    } else {
      localStorage.clear();
      window.location.href = '/uapp/login';
    }
  }
  return null;
};
export const convertPasswordStrengthMessage = (object) => {
  let newMessage = {};
  newMessage["minLength"] = `Minimum ${object.min_length} characters`;
  newMessage["maxLength"] = `Maximum ${object.max_length} characters`;
  newMessage["minUppercase"] = `${object.min_upper_case_count} Upper case character`;
  newMessage["minLowercase"] = `${object.min_lower_case_count} Lower case character`;
  newMessage["specialChar"] = `${object.min_special_chars_count } Special character`;
  newMessage["numberCount"] = `${object.min_integer_chars_count} Number`;
 
  let newMessageArray = [];

  for (const key in newMessage) {
    newMessageArray.push([key, newMessage[key]])
  }
  return newMessageArray
};

export const isPasswordValid = (password, object = {}) => {
  let listErrors = {
    minLength: true,
    maxLength: true,
    minUppercase: true,
    minLowercase: true,
    specialChar: true,
    numberCount: true,
  };
  let minEight = (password.length >= (object?.min_length || 8)) && password.length;
  let maxTwenty = (password.length <= (object?.max_length || 20)) && password.length;
  let oneUppercase = /([A-Z])/;
  let oneLowercase = /([a-z])/;
  let oneSpecialCharacter = /[@$!%*?#&]/;
  let oneDigit = /(\d)/;

  if (!minEight) {
    listErrors['minLength'] = false;
  }
  if (!maxTwenty) {
    listErrors['maxLength'] = false;
  }
  if (!password.match(oneUppercase)) {
    listErrors['minUppercase'] = false;
  }
  if (!password.match(oneLowercase)) {
    listErrors['minLowercase'] = false;
  }
  if (!password.match(oneSpecialCharacter)) {
    listErrors['specialChar'] = false;
  }
  if (!password.match(oneDigit)) {
    listErrors['numberCount'] = false;
  }

  return listErrors;
};

export const checkIsUserLoggedIn = {};

export class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'MMM yyyy').toUpperCase();
  }
  getWeekdays() {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }
}
if (locale && locale.options) locale.options.weekStartsOn = 1;
export const shLocale = locale;
