import React from "react";
import { 
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { LocalizedUtils, shLocale as locale } from 'utils/utils';
import { ThemeProvider } from "@material-ui/styles";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { LoadingButton } from '@components/button';

const backgroundColor = "#03ACEF"

const useStyles = makeStyles(theme => ({
  buttons: {
    width: '100%',
    height: '50px',
    color: 'white',
    marginTop: 25,
    [theme.breakpoints.up('sm')]: {
      width: '180px'
    }
  },
  buttonGroup: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  passwordCheck: {
    marginTop: 10,
    fontSize: 12, 
    color: '#666666'
  },
  textFieldMultiColumn: { 
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex', 
    }
  },
  subColumn: { 
    marginTop: 15, 
    [theme.breakpoints.up('sm')]: {
    }
  },
  countryCode: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    }
  },
  inputClassError: {
    width: '100% !important',
    borderColor: 'red !important',
  },
  inputClass: {
    width: '100% !important',
  },
  fieldLabel: {
    marginBottom: 5,
    marginTop: 15
  },
}));

export const RegistrationForm = ({
  fieldArray,
  error,
  validationError,
  helperText,
  validationHelperText,
  className,
  fieldValue,
  handleTextChange,
  handleDoBChange,
  handleMobileChange,
  dateFormat,
  defaultCountryCode
}) => {
  const classes = useStyles();

  return(
    fieldArray.map((field, id) => (
      <React.Fragment key={`fields-${id}`}>
        <div className={classes.fieldLabel}>
          <span id="latobold" style={{fontSize: 16}}>{field.uiLabel}</span>
        </div>
        {
          field.inputName === "gender" ?
          <div>
            <FormControl 
              error={error[field.inputName] || validationError[field.inputName]} 
              variant="outlined" 
              className={className}

            >
              <Select
                value={fieldValue[field.inputName] ?? ''} 
                onChange={handleTextChange}
                name={field.inputName}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
              {
                error[field.inputName] && <FormHelperText>{helperText}</FormHelperText>
              }
              {
                validationError[field.inputName] && <FormHelperText>{validationHelperText[field.inputName]}</FormHelperText>
              }
            </FormControl>
          </div> : 
          field.inputName === 'dateofbirth' ?
          <div style={{display: 'grid'}}>
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
              <ThemeProvider>
                <DatePicker
                  error={error[field.inputName] || validationError[field.inputName]} 
                  helperText={error[field.inputName] ? helperText : validationError[field.inputName] ? validationHelperText[field.inputName] : false}
                  openTo="year"
                  views={['year', 'month', 'date']}
                  inputVariant="outlined"
                  disableToolbar
                  variant="inline"
                  format={dateFormat}
                  value={fieldValue[field.inputName] ?? null} 
                  onChange={(event) => handleDoBChange(event, field.inputName)}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div> :
          field.inputName === 'mobilenumber' ?
          <div>
            <FormControl 
              error={error[field.inputName] || validationError[field.inputName]} 
              style={{width: '100%'}}
            >
              <PhoneInput
                country={defaultCountryCode}
                value={fieldValue[field.inputName] ?? null} 
                onChange={(phone) => handleMobileChange(phone, field.inputName)}
                specialLabel={null}
                placeholder={null}
                inputProps={
                  {  'data-testid': `user-app_field_${field.inputName}`
                    
                  }
                }
                inputClass={(error[field.inputName] || validationError[field.inputName]) ? classes.inputClassError : classes.inputClass}
              /> 
              {
                error[field.inputName] && <FormHelperText style={{marginLeft: 14}}>{helperText}</FormHelperText>
              }
              {
                validationError[field.inputName] && <FormHelperText style={{marginLeft: 14}}>{validationHelperText[field.inputName]}</FormHelperText>
              }
            </FormControl>
          </div> :
          <TextField 
            error={error[field.inputName] || validationError[field.inputName]}
            helperText={error[field.inputName] ? helperText : validationError[field.inputName] ? validationHelperText[field.inputName] : false}
            className={className} 
            variant="outlined" 
            name={field.inputName} 
            value={fieldValue[field.inputName] ?? ''} 
            onChange={handleTextChange}
            autoComplete="none"
            InputProps={
              {
                inputProps: {
                  'data-testid': `user-app_field_${field.inputName}`
                }
              }
            }
          />
        }
      </React.Fragment>
    ))
  )
}


export const RegistrationButtons = ({
  onClickPrevious,
  onClick,
  text,
  loading,
  ...props
}) => {
  const classes = useStyles();
  return(
    <div className={classes.buttonGroup}>
      <Button 
        onClick={onClickPrevious}
        disableElevation 
        disableRipple 
        variant="contained" 
        className={classes.buttons} 
        style={{backgroundColor: backgroundColor, marginRight: '30px'}}

      >
        PREVIOUS
      </Button>
      <LoadingButton
        loading={loading}
        onClick={onClick}
        variant="contained"
        component="span"
        text={text}
        className={classes.buttons} 
        style={{backgroundColor: backgroundColor, marginLeft: 'auto'}}
        disableElevation
        disableRipple
        {...props}
      />
  </div>
  )
}


export const PasswordValidation = ({
  conditions
}) => {
  const classes = useStyles();
  return(
    <div className={classes.passwordCheck}>
      <Grid container>
        <Grid item xs={6}>
          {iconUpdate(conditions[0])} 8 or more characters
        </Grid>
        <Grid item xs={6}>
          {iconUpdate(conditions[1])} 1 Number
        </Grid>
        <Grid item xs={6}>
          {iconUpdate(conditions[2])} 1 Upper Case
        </Grid>
        <Grid item xs={6}>
          {iconUpdate(conditions[3])} 1 Special Character
        </Grid>
      </Grid>
    </div>
  )
}

const iconUpdate = (condition) => (
  condition ? <CheckIcon style={{color: 'green', marginBottom: -5}} fontSize="small" /> 
  : <ClearIcon style={{color: 'red', marginBottom: -5}} fontSize="small"/>
)

export const CompleteMsg = ({
  className,
  onClick
}) => {
  return(
    <Grid container direction="column" alignItems="center" spacing={3} >
      <Grid item>
          <Typography id="latobold" style={{fontSize: 24}}>Congratulations!</Typography>
      </Grid>
      <Grid item>
        <p className={className}>
          Your account has been successfully created.<br/>
          Please <span style={{color: backgroundColor, cursor: 'pointer'}} onClick={onClick}>Sign In</span> to access your account.
        </p>
      </Grid>
    </Grid>
  )
}

export const ReviewForm = ({
  fieldArray,
  className,
  fieldValue
}) => {
  const classes = useStyles();
  return(
    fieldArray.map((field, id) => (
      <React.Fragment key={`fields-${id}`}>
        <div className={classes.fieldLabel}>
          <span id="latobold" style={{fontSize: 16}}>{field.uiLabel}</span>
        </div>
        {
          field.inputName === "gender" ?
          <div>
            <FormControl 
              variant="outlined" 
              className={className}
            >
              <Select
                value={fieldValue[field.inputName]} 
                name={field.inputName}
                data-testid={`user-app_submit_${field.inputName}`}
                disabled
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </div> :
          field.inputName === 'mobilenumber' ?
            <PhoneInput
              value={fieldValue[field.inputName]} 
              specialLabel={null}
              placeholder={null}
              inputClass={classes.inputClass}
              data-testid={`user-app_submit_${field.inputName}`}
              disabled
            /> :
          <TextField 
            className={className} 
            variant="outlined" 
            name={field.inputName} 
            value={fieldValue[field.inputName]} 
            data-testid={`user-app_submit_${field.inputName}`}
            disabled
          />
        }
      </React.Fragment>
    ))
  )
}
