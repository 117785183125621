import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as MyServiceIcon } from './icons/ic_hospital-management.svg';
import { ReactComponent as SpecialistsIcon } from './icons/ic_specialists.svg';
import { ReactComponent as HealthScreeningPackageIcon } from './icons/ic_health-screening-package.svg';
import { ReactComponent as ClinicScheduleIcon } from './icons/ic_clinicSchedule.svg';
import { ReactComponent as HealthScreeningScheduleIcon } from './icons/ic_healthScreeningSchedule.svg';
import { ReactComponent as PreAuthorizationIcon } from './icons/ic_preAuthorization.svg';
import { ReactComponent as ContractsIcon } from './icons/ic_contracts.svg';
import { ReactComponent as AppointmentsIcon } from './icons/ic_appointment.svg';
import { ReactComponent as LegacyPortalIcon } from './icons/ic_admin.svg';
import { ReactComponent as LetterOfGuaranteeIcon } from './icons/ic_letter-of-guarantee.svg';
import { ReactComponent as PackageManagementIcon } from './icons/ic_packageManagement.svg';
import { ReactComponent as FinancialCounsellingIcon } from './icons/ic_financialCounselling.svg';
import { ReactComponent as InpatientTransationTrackerIcon } from './icons/ic_inpatient-transaction-tracker.svg';
import { Helmet } from 'react-helmet-async';
import { FetchMenu } from '@smarterhealth/utilities';
import { restructureMenu } from './utils/utils';
import { PayorConfirmationContext } from '../../context/PayorConfirmationContext';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '900px',
    margin: 'auto',
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: theme.spacing(2.5),
    lineHeight: `${theme.spacing(3.8)}px`,
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: theme.spacing(1.8),
    lineHeight: `${theme.spacing(2.7)}px`,
    background: '#F6FCFF',
    color: '#C4C4C4',
    padding: theme.spacing(3),
    margin: theme.spacing(1),
    boxShadow: '3px 4px 7px rgba(166, 166, 166, 0.2)',
    border: '1px solid #C4C4C4',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    alignSelf: 'stretch',
    height: 'calc(100% - 80px)',
    '& svg': {
      width: theme.spacing(5),
      marginBottom: theme.spacing(1.2),
    },
    '&:hover': {
      background: '#FFFFFF',
      color: '#03ACEF',
    },
  },
}));

const MAP_NAME_MENU_ICON = {
  'My Services': <MyServiceIcon />,
  'Appointment Requests': <AppointmentsIcon />,
  'Find A Doctor/Make An Appointment': <SpecialistsIcon />,
  'Find A Health Screening Package/Make An Appointment': <HealthScreeningPackageIcon />,
  'Clinic Schedule': <ClinicScheduleIcon />,
  'Health Screening': <HealthScreeningScheduleIcon />,
  'Pre-Authorisation': <PreAuthorizationIcon />,
  Contracts: <ContractsIcon />,
  Appointments: <AppointmentsIcon />,
  'Legacy Portal': <LegacyPortalIcon />,
  'Letter of Guarantee': <LetterOfGuaranteeIcon />,
  'Package Management': <PackageManagementIcon />,
  'Financial counselling': <FinancialCounsellingIcon />,
  'InPatient Transaction Tracker': <InpatientTransationTrackerIcon />,
};

const MenuIcon = ({ name }) => {
  return MAP_NAME_MENU_ICON[name] || null;
};

export function getLevel(menus, label, level = 0) {
  if (menus.some((menu) => menu.label === label)) {
    return level;
  }

  for (const menu of menus) {
    const subMenus = menu.menus || menu.subMenu;
    const nextLevel = getLevel(subMenus, label, level + 1);
    if (nextLevel !== -1) {
      return nextLevel;
    }
  }

  return -1;
}

const DEFAULT_HEADER_TEXT = 'What would you like to manage?';

export const AfterLogin = () => {
  const [activeSubmenu, setActiveSubmenu] = useState([]);
  const [headerText, setHeaderText] = useState(DEFAULT_HEADER_TEXT);
  const { confirmSelectedPayor } = useContext(PayorConfirmationContext) || '';
  const { sidebarMenu, args } = FetchMenu.useMenu({ selectedPayor: confirmSelectedPayor });
  const { setNewArgs } = useContext(UserContext);
  useEffect(() => {
    setNewArgs({ ...args });
  }, [args]);

  const clickMenu = (item) => {
    const level = getLevel(sidebarMenu, item.label);
    setHeaderText(level > 0 ? item.label : DEFAULT_HEADER_TEXT);
    const subMenus = item.menus || item.subMenu;

    if (subMenus?.length) {
      setActiveSubmenu(subMenus);
    } else {
      window.location.href = item.url;
    }
  };


  const classes = useStyles();
  let menus = activeSubmenu.length ? activeSubmenu : sidebarMenu;
  menus = restructureMenu(menus);


  useEffect(() => {
    setActiveSubmenu([]);
  }, [sidebarMenu]);

  return (
    <>
      <Helmet>
        <title>Choose Menu</title>
      </Helmet>
      <div className={classes.container}>
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          {headerText}
        </Typography>
        <Grid container spacing={1}>
          {menus.map((subMenu, index) => {
            return (
              <Grid item xs={12} sm={6} key={subMenu.label}>
                <div
                  className={classes.menuItem}
                  onClick={() => clickMenu(menus[index])}
                  data-testid={`afterLogin_submenu-${index}`}
                >
                  <MenuIcon name={subMenu.label} />
                  {subMenu.label}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};