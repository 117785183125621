import React, { useEffect } from 'react';
import { getMetaData } from "../../apis";
import { postLoginData } from "./utils/utils";
import { useNavigate } from "react-router-dom";
import Loading from '../../images/loading.gif';
import Cookies from "universal-cookie";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    textAlign: 'center',
    paddingTop: '10vh'
  },
  text: {
    fontWeight: 600,
    fontSize: theme.spacing(1.8)
  }
}));


export const OktaSamlAuthentication = () => {
  const cookies = new Cookies();
  const accessToken = cookies.get("okta_access_token");
  const refreshToken = cookies.get("okta_refresh_token");
  const expiry = cookies.get("okta_exp");
  const tokenType = cookies.get("okta_token_type");
  const lifetimeSeconds = cookies.get("okta_lifetime_seconds");
  document.cookie = `auth_token=${accessToken}; path=/`;
  document.cookie = `refresh_token=${refreshToken}; path=/`;
  let navigate = useNavigate();

  async function fetchData() {
    try {
      if(accessToken) {
        let user = {
          access_token: accessToken,
          exp: expiry,
          refresh_token: refreshToken,
          token_type: tokenType,
          lifetime_seconds: lifetimeSeconds
        }
        localStorage.setItem('user', JSON.stringify(user));
        try {
          let res = await getMetaData();
          if(res) {
            let meta = res.data ;
            user = {
              ...user,
              roles: meta?.roleNames,
              id: null
            }
            postLoginData(user, meta);
            navigate("/choose-menu");
          }
        }
        catch(error) {
          navigate("/no-permission?isOkta=true");
        }
      }
      else {
        navigate("/no-permission?isOkta=true");
      }
    } 
    catch(err) {
      navigate("/no-permission?isOkta=true");
    }       
  }

  useEffect(async ()=> { 
    await fetchData();
  },[])
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={Loading} />
      <Typography
        component="p"
        className={classes.text}
      >Please wait while we are loading...</Typography>
    </div>
  );
};
