import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { RegistrationForm } from './RegistrationComponents.jsx';
import { LoadingButton } from '@components/button';
import { Wrapper } from './Wrapper.jsx';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const backgroundColor = "#03ACEF"
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(2.6),
    fontWeight: 600
  },
  container:{
    flexGrow: 1,
  },
  divContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
  userInput: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '520px',
    }
  },
  buttons: {
    width: '180px',
    height: '50px',
    color: 'white',
    marginTop: 25,
  },
  mobileView: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      justifyItems: 'center'
    }
  }
}));

export const PersonalProfile = (props) => {
  const convertDOB = (date) => new Date(date).toISOString().split('T')[0]
  const fields = [
    { inputName:  "firstname", isRequired: true, name: "First Name", uiLabel: "First Name/Given Name (as per National Identity Card)" },
    { inputName:  "lastname", isRequired: true, name: "Last Name", uiLabel: "Last Name/Family Name (as per National Identity Card)" },
    { inputName:  "gender", isRequired: true, name: "Gender", uiLabel: "Gender" },
    { inputName:  "dateofbirth", isRequired: true, name: "Date of Birth", uiLabel: "Date of Birth"},
    { inputName:  "mobilenumber", isRequired: true, name: "Mobile Number", uiLabel: "Mobile Number" }
  ]
  const dateFormat = "yyyy-MM-dd"
  const [formFields, setFormFields] = useState(fields);
  const [isEmptyFieldError, setIsEmptyFieldError] = useState({});
  const [isValidationError, setIsValidationError] = useState({});
  const [validationErrorHelperText, setValidationErrorHelperText] = useState({});
  const [fieldValue, setFieldValue] = useState({})  
  const [helperText, setHelperText] = useState("This field cannot be empty");
  const [userId, setUserId] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientCountry, setClientCountry] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const searchParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();


  const getDefaultCountryCode = () => {
    const clientCountry = cookies.get('clientCountry')

    if (clientCountry) {
      if (clientCountry.toLowerCase() === 'malaysia'){
        return 'my'
      }
      else if (clientCountry.toLowerCase() === 'indonesia'){
        return 'id'
      }
      else {
        return 'sg'
      }
    }
    else {
      return 'sg'
    }
  }

  const handleTextChange = (event) => {
    formFields.forEach((field, id) => {
      if(event.target.name === field.inputName){
        setFieldValue({...fieldValue, [event.target.name]: event.target.value});
        setIsEmptyFieldError({...isEmptyFieldError, [event.target.name]: false});
        setIsValidationError({...isValidationError, [event.target.name]: false})  
      }
    })
  }

  const handleDoBChange = (event, name) => {
    setFieldValue({...fieldValue, [name]: convertDOB(event)});
    setIsEmptyFieldError({...isEmptyFieldError, [name]: false})
  }

  const handleMobileChange = (phone, name) => {
    setFieldValue({...fieldValue, [name]: `+${phone}`});
    setIsEmptyFieldError({...isEmptyFieldError, [name]: false});
  }

  const onSubmit = () => {
    const isPass = Object.keys(fieldValue).every((f) => fieldValue[f])
    if(!isPass){
      const newObject = {}
      Object.keys(fieldValue).forEach((key, index) => {
        newObject[key] = !fieldValue[key] ? true : false
      });
      setIsEmptyFieldError(newObject)
      return
    }
    
    if(!userId){
      console.log('could not retrieve userId')
      return;
    }

    setIsLoading(true);

    const registrationJson = {
      firstname: fieldValue.firstname,
      lastname: fieldValue.lastname,
      gender: fieldValue.gender.toLowerCase(),
      birthday: fieldValue.dateofbirth,
      contact_number: [{"contactNumber": fieldValue.mobilenumber, "type": "personal", "preferred": true}]
    }
    navigate(`/signup/${clientName}/review/submit`, {state: {userId: userId, formFields: formFields, formValues: fieldValue, dateFormat: dateFormat, registrationJson: registrationJson}});
  }

  const editFields = () => {
    if(location?.state?.formValues){
     setFieldValue({ fieldValue: location.state.formValues, isEdit: true })
    }
  }
  const initFormFields = () => {
    const fieldObject = {}
    const errorObject = {}
    formFields.forEach((field, id) => {
      fieldObject[field.inputName] = null
      errorObject[field.inputName] = false
    })
    setFieldValue(fieldObject);
    setIsEmptyFieldError(errorObject); 
    setIsValidationError(errorObject);
    setValidationErrorHelperText(fieldObject)
  }

  useEffect(() => {
    const client = searchParams?.clientName ? searchParams.clientName : null
    const userId = location?.state?.userId;
    setUserId(userId)
    setClientName(client)
    initFormFields()
    editFields();
    setClientCountry(getDefaultCountryCode());
  }, []);

  return (
    <Wrapper>
      <Grid container direction="row" justifyContent="space-evenly" className={classes.container}>
        <Grid item xs={12} className={classes.mobileView}>
          <div className={classes.divContainer}>
            <Grid container direction="column" alignItems="center" spacing={3}>
                <Grid item style={{marginRight: 'auto'}}>
                  <Typography id="title" className={classes.title}>Personal Profile</Typography>
                </Grid>
                <Grid item style={{width: '100%', marginBottom: 20}}>
                  <form autoComplete="off">
                    <RegistrationForm
                      fieldArray={formFields ? formFields : []}
                      error={isEmptyFieldError}
                      validationError={isValidationError}
                      helperText={helperText}
                      validationHelperText={validationErrorHelperText}
                      className={classes.userInput}
                      fieldValue={fieldValue}
                      handleTextChange={handleTextChange}
                      handleDoBChange={handleDoBChange}
                      handleMobileChange={handleMobileChange}
                      dateFormat={dateFormat}
                      defaultCountryCode={isEdit ? null : clientCountry}
                    />

                    <LoadingButton
                      loading={isLoading}
                      text="Next"
                      onClick={onSubmit} 
                      disableElevation
                      disableRipple 
                      variant="contained"
                      component="span"
                      data-testid="user-app_personalProfile_submit"
                      className={classes.buttons} 
                      style={{backgroundColor: backgroundColor, marginLeft: 'auto', width: '100%'}}
                    />
                  </form>
                </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}