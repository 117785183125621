import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: (props) => ({
    pointerEvents: props.disabled && 'none',
  }),
}));

export const SmarterNumeric = ({
  inputRef,
  InputProps,
  maxValue,
  minValue = 0,
  value,
  thousandSeparator = ',',
  decimalSeparator = '.',
  allowNegative = false,
  setValue = null,
  variant = 'outlined',
  suffix = null,
  onChange,
  disabled = false,
  ...props
}) => {
  const classes = useStyles({ disabled });
  const handleOnChange = (event) => {
    let newValue = event.target.value;
    if (props.prefix) newValue = newValue.replace(props.prefix, '');
    onChange(newValue);
  };

  return (
    <NumberFormat
      variant={variant}
      fullWidth
      inputRef={inputRef}
      value={value}
      onChange={handleOnChange}
      disabled={disabled}
      customInput={TextField}
      className={classes.root}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={allowNegative}
      {...props}
    />
  );
};
