import HttpServices from './http_services';
import { userAuthStorage } from './utils';

const AUTH_VERSION = 'v2';

function logout() {
  userAuthStorage.removeUserAuth();
}


const verifyToken = (params = {}) => {
  const { token } = params;
  return HttpServices.publicPost(`/auth/${AUTH_VERSION}/auth/verify`, {
    token,
  });
};

async function revalidateAccessToken(refreshToken) {
  // When getting fresh access token, we need the refreshToken as the bearer for
  // `Authorization` header.
  const res = await HttpServices.publicGet(`/auth/${AUTH_VERSION}/auth/token/jwt/refresh`, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  const userAuth = userAuthStorage.getUserAuth();

  const nextUserAuth = {
    // Preserve roles and id.
    ...userAuth,
    // Change the existing "access" and "refresh" token.
    ...res.data,
  };

  // Update userAuth in the storage.
  userAuthStorage.setUserAuth(JSON.stringify(nextUserAuth));

  return nextUserAuth;
}

const AuthService = {
  logout,
  verifyToken,
  revalidateAccessToken,
};
export default AuthService;
