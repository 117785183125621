import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStylesBase } from '../useStylesBase.js';

export const FormTitle = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { type, contactNumbers, setContactNumbers, emails, setEmails, addresses, setAddresses } =
    props;

  const baseClasses = useStylesBase();
  const clickAddItem = () => {
    setIsDisabled(true)
    if (type === 'contactNumber') {
      let count = 0;
      const contactNumbersMirror = [];
      contactNumbers.forEach((contactNumber) => {
        if (contactNumber.id) {
          count++;
        }
        contactNumbersMirror.push(contactNumber);
      });
      contactNumbersMirror.push({
        preferred: false,
        type: 'personal',
        contactNumber: '',
      });
      if (contactNumbersMirror.length - count === 1) {
        setContactNumbers(contactNumbersMirror);
      }
    } else if (type === 'email') {
      let count = 0;
      const emailsMirror = [];
      emails.forEach((email) => {
        if (email.id) {
          count++;
        }
        emailsMirror.push(email);
      });

      emailsMirror.push({
        preferred: false,
        type: 'personal',
        email: '',
      });
      if (emailsMirror.length - count === 1) {
        setEmails(emailsMirror);
      }
    } else {
      let count = 0;
      const addressesMirror = [];
      addresses.forEach((address) => {
        if (address.id) {
          count++;
        }
        addressesMirror.push(address);
      });
      addressesMirror.push({
        type: 'personal',
        unit: '',
        block: '',
        street: '',
        zipcode: '',
        city: '',
        buildingName: '',
        country: '',
        region: '',
        location: '',
        preferred: false,
        floor: '',
        otherInfo: ''
      });
      if (addressesMirror.length - count === 1) {
        setAddresses(addressesMirror);
      }
    }
  };

  const item = (() => {
    let title, buttonText;
    if (type === 'contactNumber') {
      title = 'Contact Number';
      buttonText = 'Add New Contact Number';
    } else if (type === 'email') {
      title = 'Email Address';
      buttonText = 'Add New Email';
    } else {
      title = 'Address Details';
      buttonText = 'Add New Address';
    }
    return { title, buttonText };
  })();
  
  let titleClassName = `${baseClasses.formTitle} ${baseClasses.formTitleDisabled}`;
  if(!isDisabled) {
    titleClassName = `${baseClasses.formTitle}`
  }
  return (
    <Typography component="h3" variant="subtitle2" className={`${titleClassName}`}>
      {item.title}
      <div data-testid={`set-${type}`} onClick={clickAddItem}>
        <Add alt="addItem" />
        {item.buttonText}
      </div>
    </Typography>
  );
};

FormTitle.propTypes = {
  type: PropTypes.string,
  contactNumbers: PropTypes.array,
  setContactNumbers: PropTypes.func,
  emails: PropTypes.array,
  setEmails: PropTypes.func,
  addresses: PropTypes.array,
  setAddresses: PropTypes.func,
};
