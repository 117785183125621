import * as Yup from "yup";

export const RegisterValidationSchema = Yup.object({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Please enter a valid email format').required('Email is required'),
  gender: Yup.string().required('Gender is required'),
  birthday: Yup.date().required('Date of Birth is required').nullable(),
  contact_number: Yup.string().required('Phone number is required')
});
