import React from 'react';
import { Step, StepLabel, Stepper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    background: 'white', 
    paddingTop: 30,
    '& .MuiStepIcon-text': {
      fill: '#fff !important'
    }
  },
  root: {
    background: 'white',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '-webkit-fill-available',
    },
    display: 'flex',
    justifyContent: 'center',
  }, 
}));

export const Wrapper = (props) => {
  const classes = useStyles();
  const isStep1 = window.location.pathname.includes('/accountsettings');
  const isStep2 = window.location.pathname.includes('/personalprofile');
  const isStep3 = window.location.pathname.includes('/review/submit');
  const isSignUpCompleted = window.location.hash.includes('#completed') ? true : false;

  const activeStep = () => {
    if (isSignUpCompleted) {
      return 3;
    } else if (isStep1) {
      return 0;
    } else if (isStep2) {
      return 1;
    } else if (isStep3) {
      return 2;
    }
  };

  return (
    <React.Fragment>
        <div className={classes.stepperContainer}>
          <Stepper
            activeStep={activeStep()}
            alternativeLabel
            style={{ backgroundColor: 'transparent', width: '100%' }}
          >
            <Step>
              <StepLabel>Account Settings</StepLabel>
            </Step>
            <Step>
              <StepLabel>Personal Profile</StepLabel>
            </Step>
            <Step>
              <StepLabel>{`Review & Submit`}</StepLabel>
            </Step>
          </Stepper>
        </div>
        <div className={classes.root}>
            <div style={{ minWidth: 200, margin: 30, flex: 1 }}>{props.children}</div>
        </div>
    </React.Fragment>
  );
}