import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useStylesBase } from '../useStylesBase.js';

export const Heading = ({ title }) => {
  const baseClasses = useStylesBase();
  return (
    <>
      <span className={baseClasses.breadcrumb}>
        Homepage > <span>{title}</span>
      </span>
      <Typography component="h3" className={baseClasses.accountInformationHeading}>
        Account Information
      </Typography>
    </>
  );
};

Heading.propTypes = {
  title: PropTypes.string,
};
