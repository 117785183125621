export const PUBLIC_URL = process.env.PUBLIC_URL;
export const SMART_PANEL_PREFIX = `/smart-panel/api`;
export const SMART_CONTRACT_PREFIX = `/smart-contract/api`;
export const SMART_PANEL_ADMIN_PREFIX = `/smart-panel/admin/api`;
export const ORIGIN_LOCATION = window && window.location && window.location.origin;
export const BASE_URL =
  process.env.REACT_APP_ARROW_URL || `${ORIGIN_LOCATION}/` || 'http://localhost:3005/';
export const API_VERSION = 'v1';
export const TOKEN_DEFAULT = 'abc123';
export const PLATFORM_PREFIX = 'discovery/api';
export const PUBLIC_TOKEN = 'abc123';
export const SMART_CONTRACT = '/smart-contract/api';
