import React, { useState } from 'react';
import { SmarterTextField } from '@components/input';
import { FormItem } from '../../components/form/FormItem.jsx';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleVisibilityIcon } from "@components/toggle-visibility-icon";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  textField: {
    position: 'relative',
    marginBottom: '30px',
    '& label': {
      fontSize: theme.spacing(1.6),
      lineHeight: `${theme.spacing(2.4)}px`,
    },
    '& svg': {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(5.8),
    },
    '& p': {
      marginLeft: '0px',
    },
  },
}));

export const Password = ({ label, id, onChangePassword, error, helperText = '' }) => {
  const [isVisibility, setIsVisibility] = useState(false);
  const classes = useStyles();

  const alternateIcon = () => {
    setIsVisibility(!isVisibility);
  };
  return (
    <div className={classes.textField}>
      <FormItem label={label} id={id}>
        <SmarterTextField
          onChange={onChangePassword}
          id={id}
          error={error}
          helperText={helperText}
          type={isVisibility ? 'text' : 'password'}
          inputProps={{ 'data-testid': id }}
          allowClear={false}
        />
      </FormItem>
      <ToggleVisibilityIcon visible={isVisibility} onToggle={alternateIcon} />
    </div>
  );
};

Password.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  onChangePassword: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
