import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { InputTitle } from "./InputTitle.jsx";
import { SmarterTextField } from "../../../components/input";
import { requiredFieldErrorMessage } from "../../../features/common/config"

export const Address = (props) => {
  const {
    index,
    addresses,
    patchAddresses,
    setPatchAddresses,
    setAsPreferred,
    isDisabled = false,
    deleteAddress,
    setAddresses,
    isSubmitted,
    userDataLength,
  } = props;

  const handleAddress = (type) => {
    return (value) => {
      let newAddress = addresses.map((address, aIdx) => {
        
        let temp = { ...address };
        if (index === aIdx) {
          temp[type] = value;
          if(address.id) {
            const newPatchAddresses = patchAddresses.add(address.id);
            setPatchAddresses(newPatchAddresses);
          }
        }
        return temp;
      });

      setAddresses(newAddress);
    };
  };

  return (
    <Fragment key={`${index}-address`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputTitle
            index={index}
            items={addresses}
            setAsPreferred={setAsPreferred}
            deleteItem={deleteAddress}
            type="address"
            userDataLength={userDataLength}
          />
          <label htmlFor="street-name">Address 1(Street Name)*</label>
          <SmarterTextField
            value={addresses[index].street}
            disabled={isDisabled}
            onChange={handleAddress("street")}
            error={
              isSubmitted && addresses[index].street.length === 0 ? true : false
            }
            helperText={
              isSubmitted && addresses[index].street.length === 0
                ? requiredFieldErrorMessage
                : ""
            }
            id="street-name"
            inputProps={{ "data-testid": `streetname-${index}` }}
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="buildingName">Address 2(Building Name)</label>
          <SmarterTextField
            value={addresses[index].buildingName}
            disabled={isDisabled}
            onChange={handleAddress("buildingName")}
            id="buildingName"
            inputProps={{ "data-testid": `buildingName-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="blockNo">House/Block No*</label>
          <SmarterTextField
            value={addresses[index].block}
            disabled={isDisabled}
            error={
              isSubmitted && addresses[index]?.block.length === 0 ? true : false
            }
            helperText={
              isSubmitted && addresses[index]?.block.length === 0
                ? requiredFieldErrorMessage
                : ""
            }
            onChange={handleAddress("block")}
            id="blockNo"
            inputProps={{ "data-testid": `blockNo-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="zipcode">Postal Code*</label>
          <SmarterTextField
            value={addresses[index]?.zipcode}
            disabled={isDisabled}
            error={isSubmitted && !addresses[index]?.zipcode?.length}
            helperText={isSubmitted && !addresses[index]?.zipcode?.length && requiredFieldErrorMessage}
            onChange={handleAddress("zipcode")}
            id="zipcode"
            inputProps={{ "data-testid": `zipcode-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="level">Level</label>
          <SmarterTextField
            value={addresses[index]["floor"]}
            disabled={isDisabled}
            onChange={handleAddress("floor")}
            id="level"
            inputProps={{ "data-testid": `level-${index}` }}
          />
        </Grid>
        <Grid item xs={6}>
          <label htmlFor="number">Number</label>
          <SmarterTextField
            value={
              addresses[index].unit 
            } 
            disabled={isDisabled}
            onChange={handleAddress("unit")}
            id="number"
            inputProps={{ "data-testid": `number-${index}` }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
Address.propTypes = {
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  addresses: PropTypes.array.isRequired,
  setAsPreferred: PropTypes.func.isRequired,
  setAddresses: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  userDataLength: PropTypes.number.isRequired,
};
