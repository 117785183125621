import { Button, Typography } from '@material-ui/core';
import { GeneralError } from './GeneralError';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: `${theme.spacing(60)}px`,
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    '& label': {
      textAlign: 'left',
      display: 'block',
      color: '#000',
    },
    '& *': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  },
  innerContainer: {
    background: '#fff',
    padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`,
    borderRadius: theme.spacing(0.4),
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  },
  content: {
    maxWidth: `${theme.spacing(40)}px`,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
 
    '& p': {
      marginLeft: '0px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    marginBottom: '15px',
    fontFamily: 'Playfair Display, sans-serif !important',
  },
  button: {
    color: '#fff',
    background: '#000',
    fontSize: theme.spacing(1.8),
    width: '100%',
    marginTop: theme.spacing(0.8),
    lineHeight: `${theme.spacing(2.6)}px`,
    padding: `${theme.spacing(1.6)}px ${theme.spacing(2.4)}px`,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#000',
    },
  },
  marginTopZero: {
    marginTop: '0px',
  },
}));
export const ResetPasswordPage = ({
  title,
  description,
  children,
  buttonProps,
  error,
  handleClose,
  resendCode = null,
  successfulPage = false,
}) => {
  const classes = useStyles();
  const { click, text, ...props } = buttonProps;
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Typography className={classes.title} component="h2">
          {title}
        </Typography>
        <Typography component="p">{description}</Typography>
        <div className={classes.content}>
          {children}
          <Button
            variant="contained"
            className={`${classes.button} ${successfulPage ? classes.marginTopZero : ''}`}
            onClick={click}
            {...props}
          >
            {text}
          </Button>
          {resendCode}
        </div>
      </div>
      <GeneralError onClose={handleClose} isOpen={error} />
    </div>
  );
};
