import React, { useState, useEffect } from 'react';
import { getVerifyToken } from 'apis';
import { Metadata } from '@smarterhealth/utilities';
import { PageNotFound } from '@smarterhealth/ui-components';
import { redirectUser } from '../../../utils/utils';

export const NotFound = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { metadata } = Metadata.useMetadata({});

  useEffect(async () => {
    let verify = await getVerifyToken();
    if (verify?.status === 200) {
      setIsLoggedIn(true);
    }
  }, []);
  return (
    <PageNotFound
      isLoggedIn={isLoggedIn}
      onBackToHomePage={() => redirectUser(metadata, isLoggedIn)}
    />
  );
};
