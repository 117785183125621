import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, Button } from '@material-ui/core';
import { ClearRounded } from '@material-ui/icons';

export const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    display: 'block',
  },

  dialog: {
    textAlign: 'center',
    padding: `${theme.spacing(4)}px ${theme.spacing(7)}px`,
    position: 'relative' 
  },

  button: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(2.8)}px`,
    cursor: 'pointer',
    padding: theme.spacing(1.6),
    textTransform: 'capitalize',
    color: '#fff',
    backgroundColor: '#000',
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(3)
  },

  closeModal: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: 'pointer'
  }
}));


export const LoginModal = (props) => {
  const { onClose, isOpen, clickResetPassword } = props;
  const classes = useStyles();


  return (
    <Dialog onClose={onClose} open={isOpen}>
      <div className={classes.dialog}>
        <ClearRounded className={classes.closeModal} onClick={onClose} />
        <Typography component="h2" variant="subtitle1" className={classes.pageTitle}>
          Password Expired
        </Typography>
        <Typography component="p">
          For security purposes, you’ll need to change your password every 90 days.
        </Typography>
        <Typography component="p">
          To login, click the button below to set a new password.
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          onClick={clickResetPassword}
          data-testid="uapp_loginModal-submit"
        >
          Reset Password
        </Button>
      </div>
    </Dialog>
  );
};

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  clickResetPassword: PropTypes.func,
  onClose: PropTypes.func
};

