import { createTheme } from '@material-ui/core';
import { PALETTE_COLORS } from './constants';

export const userTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1140,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#03ACEF',
      light: '#088CCA',
    },
    shades: {
      transparent: 'rgba(0,0,0,0.5)',
    },
    neutral: {
      [PALETTE_COLORS.main]: '#7F7F7F',
      [PALETTE_COLORS.light]: '#F2F2F2',
      [PALETTE_COLORS.dark]: '#262626',
      [PALETTE_COLORS['100']]: '#F2F2F2',
      [PALETTE_COLORS['200']]: '#D9D9D9',
      [PALETTE_COLORS['300']]: '#BFBFBF',
      [PALETTE_COLORS['400']]: '#A6A6A6',
      [PALETTE_COLORS['500']]: '#7F7F7F',
      [PALETTE_COLORS['600']]: '#595959',
      [PALETTE_COLORS['700']]: '#404040',
      [PALETTE_COLORS['800']]: '#262626',
    },
  },

  spacing: 10,
});
