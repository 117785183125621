import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { postAuth0Login, getMetaData, putEnableStatus } from "../../apis";
import { postLoginData } from "../login/utils/utils";
import Loading from '../../images/loading.gif';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";


const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    textAlign: 'center',
    paddingTop: '10vh'
  },
  text: {
    fontWeight: 600,
    fontSize: theme.spacing(1.8)
  }
}));


export const Auth0AuthenticationPage = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const queryParams = new URLSearchParams(window.location.search);
  const username = queryParams.get('username');
  let navigate = useNavigate();

  async function fetchData() {
    let token = await getAccessTokenSilently();
    const formData = new FormData();
    formData.append("auth0_access_token", token);
    let resUser = await postAuth0Login(formData);

    if (resUser?.data?.access_token) {
      try {
        let res = await getMetaData();
        if (res) {
          let meta = res.data;
          let user = resUser.data;
          user = {
            ...user,
            roles: meta?.roleNames,
            id: null
          }
          postLoginData(user, meta);
          navigate("/choose-menu");
        }
      }
      catch (error) {
        navigate("/no-permission");
      }
    }
    else {
      navigate("/no-permission?isAuth=true");
    }
  }



  useEffect(async () => {
    if (username) {
      try {
        let res = await putEnableStatus(username)
        if (res) {
          navigate("/auth-login");
        }
      }
      catch (error) {
        navigate("/no-permission?isAuth=true");
      }
    }
    else {
      fetchData();
    }
  }, [])


  return (
    <div className={classes.container}>
      <img src={Loading} />
      <Typography
        component="p"
        className={classes.text}
      >Please wait while we are loading...</Typography>
    </div>
  );
};
