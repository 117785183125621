import React from 'react';
import { ResetPasswordPage } from './ResetPasswordPage';
import PropTypes from 'prop-types';

export const PasswordSuccessfullyUpdated = ({ isAuth0 = false }) => {
  const redirect = async () => {
    if (!isAuth0) {
      const clientId = window.sessionStorage.getItem('client');
      if (clientId) {
        window.location.href = `/uapp/${clientId}/login`;
      }
      else {
        window.location.href = "/uapp/login";
      }
    }
    else {
      window.location.href = "/uapp/auth-login"
    }
  };
  return (
    <ResetPasswordPage
      title={isAuth0 ? "Reset password link has been sent" : "Password Successfully Updated"}
      description={isAuth0 ? "" : "You may now proceed to login"}
      buttonProps={{
        text: 'Login now',
        onClick: redirect,
      }}
      successfulPage={true}
    />
  );
};

PasswordSuccessfullyUpdated.propTypes = {
  isAuth0: PropTypes.bool
};
