import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { AppTheme } from "../src/features/app_theme/theme_type";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { detectEnv } from "./utils/utils";

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

/* istanbul ignore next */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", process.env.REACT_APP_ARROW_URL, /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  environment: detectEnv(),
});

const Root = () => {
  const [theme, setTheme] = React.useState(AppTheme.light);

  const changeTheme = () => {
    setTheme(theme === AppTheme.light ? AppTheme.dark : AppTheme.light);
  };

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App changeTheme={changeTheme} theme={theme} />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
};

Sentry.withProfiler(Root);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
