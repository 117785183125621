import { makeStyles } from '@material-ui/core/styles';
import { MasterLayout, NoPermission } from '@smarterhealth/ui-components';
import { redirectUser } from 'utils/utils';
import { checkIsUserLoggedIn } from 'utils/AuthService';
import { Metadata } from '@smarterhealth/utilities';
import Cookies from "universal-cookie";

export function NoPermissionPage({ ...props }) {
  const cookies = new Cookies();
  const samlErrorMessage = cookies.get("saml_error_message");
  const isLoggedIn = checkIsUserLoggedIn();
  const { metadata } = Metadata.useMetadata({});
  const queryParams = new URLSearchParams(window.location.search);
  const isAuth0 = queryParams.get('isAuth0') || queryParams.get('isOkta');

  const classes = useStyles();
  const config = {
    backToText: isLoggedIn ? 'Back to Homepage' : 'Back to Login',
    onBack: () => redirectUser(metadata, isLoggedIn),
    isOkta: isAuth0
  };

  return (
    <MasterLayout {...props} classes={{ container: isAuth0 ? classes.containerOkta : classes.container }}>
      <NoPermission {...config} />
      {samlErrorMessage && (<p className={classes.samlError}>{samlErrorMessage}</p>)}
    </MasterLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#ffffff'
  },
  containerOkta: {
    backgroundColor: '#ffffff',
    '& p:nth-child(3)': {
      display: 'none'
    }
  },
  samlError: {
    margin: '0 0 45px 0',
    fontSize: theme.spacing(2.2),
    fontWeight: 400,
    lineHeight: 1.1,
    textAlign: 'center'
  }
}));
